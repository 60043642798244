import {
  Button,
  TextField,
  Checkbox,
  Label,
  Toast,
  Page,
  Layout,
  FormLayout,
  Banner,
  BlockStack,
  Text,
  Card,
} from "@shopify/polaris";
import { useState, useCallback, useRef, useEffect } from "react";
import { GetApiCall, ApiCall } from "../helpers/axios";
import EmailEditor from "react-email-editor";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";

const PreOrderEmail = () => {
  const navigate = useNavigate();
  const emailEditorRef = useRef(null);
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  let store_client_id = shop_data.store_client_id;
  const [activeToast, setActiveToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const header = { authentication: shopinfo.auth_token };
  const [loading, setLoading] = useState(false);
  const [authStatus, setAuthstatus] = useState(1);

  const [initialState, setInitialState] = useState({
    email_Status: "",
    sender_email_address: "",
    recipent_email_addresss: "",
    email_Subject: "",
    advanced_setting_checkbox: "",
    bcc_email: "",
    sender_email_address: "",
  });

  const formSchema = Yup.object().shape({
    // sender_email_address: Yup.string().required('Sender email address is required'),
    email_Subject: Yup.string().required("Email subject is required"),
    advanced_setting_checkbox: Yup.boolean(),
    bcc_email: Yup.string().when("advanced_setting_checkbox", {
      is: true,
      then: Yup.string().required("BCC email is required"),
    }),
  });

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: formSchema,
    enableReinitialize: true,
    onSubmit: () => {
      exportEmailTemplate();
    },
  });

  const getAllDefaultsetting = async () => {
    if (Object.keys(shop_data).length > 0) {
      let res = await GetApiCall(
        "GET",
        `/settings?store_client_id=${store_client_id}`,
        header
      );
      if (res?.data?.statusCode === 200 && res?.data?.status === "success") {
        let pre_order_email = res?.data?.data[0]?.email_settings
          ? JSON.parse(res?.data?.data[0]?.email_settings)
          : "";
        if (pre_order_email) {
          formik.setFieldValue(
            "sender_email_address",
            pre_order_email.sender_email_address
          );
          formik.setFieldValue(
            "recipent_email_addresss",
            pre_order_email.pre_order_email_recipent_email_addresss
          );
          formik.setFieldValue(
            "advanced_setting_checkbox",
            pre_order_email.pre_order_email_advanced_setting_checkbox
          );
          formik.setFieldValue(
            "bcc_email",
            pre_order_email.pre_order_email_bcc_email
          );
        }
      }
    }
  };

  useEffect(() => {
    getAllDefaultsetting();
  }, [store_client_id, shop_data]);

  const saveEmailTemplate = async (design, html) => {
    setLoading(true);
    const data = {
      store_client_id: store_client_id,
      emailSubject: formik.values.email_Subject,
      emailHtml: btoa(JSON.stringify(html)),
      emailDesign: btoa(JSON.stringify(design)),
      pre_order_emails: {
        sender_email_address:
          formik.values.sender_email_address !== undefined
            ? formik.values.sender_email_address
            : "",
        pre_order_email_recipent_email_addresss:
          formik.values.recipent_email_addresss !== undefined
            ? formik.values.recipent_email_addresss
            : "",
        pre_order_email_advanced_setting_checkbox:
          formik.values.advanced_setting_checkbox !== undefined
            ? formik.values.advanced_setting_checkbox
            : "",
        pre_order_email_bcc_email:
          formik.values.bcc_email !== undefined ? formik.values.bcc_email : "",
      },
    };
    const response = await ApiCall("POST", "/email-template", data, header);
    if (response.data.statusCode === 200 || response.data.statusCode === 400) {
      setToastMessage(response.data.message);
      toggleActiveToast();
      setLoading(false);
    }
  };

  const exportEmailTemplate = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      saveEmailTemplate(design, html);
    });
  };

  const onLoad = async () => {
    /* editor instance is created */
    /* you can load your template here; */
    let response = await GetApiCall(
      "GET",
      `/get-email-template?store_client_id=${store_client_id}`,
      header
    );
    if (
      response.data.status === "success" &&
      response.data.statusCode === 200
    ) {
      let email_template = response.data?.data[0];
      formik.setFieldValue("email_Subject", email_template?.email_subject);
      const templateJson = JSON.parse(atob(email_template?.email_design));
      emailEditorRef.current.editor.loadDesign(templateJson);
    }
  };

  const toggleActiveToast = useCallback(
    () => setActiveToast((active) => !active),
    []
  );

  const toastMarkup = activeToast ? (
    <Toast
      content={toastMessage}
      onDismiss={toggleActiveToast}
      duration={2000}
    />
  ) : null;

  const sendTestMail = async () => {
    if (
      formik.values.recipent_email_addresss &&
      formik.values.sender_email_address !== ""
    ) {
      let data = {
        to: formik.values.recipent_email_addresss,
        from: formik.values.sender_email_address,
      };
      let res = await ApiCall("POST", "/send-email", data, header);
      if (res && (res.data.statusCode === 200 || res.data.statusCode === 400)) {
        setToastMessage(res.data.message);
        toggleActiveToast();
      } else {
        setToastMessage("email not send..!!");
        toggleActiveToast();
      }
    }
  };

  const checkVerified = async () => {
    if (
      formik.values.sender_email_address !== "" &&
      Object.keys(shop_data).length > 0
    ) {
      if (shop_data.email_verified_status === "0") {
        await ApiCall(
          "POST",
          `/checkEmailVerify`,
          { email: formik.values.sender_email_address },
          header
        );
        setAuthstatus(1);
      } else {
        setAuthstatus(0);
      }
    }
  };

  useEffect(() => {
    checkVerified();
  }, [formik.values.sender_email_address]);

  const sendAuthMail = async () => {
    if (
      formik.values.sender_email_address &&
      Object.keys(shop_data).length > 0
    ) {
      let data = {
        data: {
          nickname: shop_data.shop_owner,
          from_email: formik.values.sender_email_address,
          from_name: shop_data.shop_owner,
          reply_to: "conversionbat@gmail.com",
          reply_to_name: "conversionbat",
          address: shop_data.address1,
          address2: shop_data.address2,
          state: shop_data.province
            ? shop_data.province
            : shop_data.country_code,
          city: shop_data.city,
          country: shop_data.country_code,
          zip: shop_data.zip,
        },
      };
      let res = await ApiCall("POST", "/sendVerifyEmail", data, header);
      if (res && (res.data.statusCode === 200 || res.data.statusCode === 400)) {
        setToastMessage(res.data.message);
        toggleActiveToast();
      } else {
        setToastMessage("Already exists..!!");
        toggleActiveToast();
      }
    }
  };

  return (
    <>
      <Page
        fullWidth
        backAction={{
          content: "Settings",
          onAction: () => navigate("/setting"),
        }}
        title={"Pre-order Emails"}
      >
        <Layout>
          {authStatus ? (
            <>
              <Layout.Section>
                <Banner
                  title="Please authenticated your sendgrid account before send pre-order emails."
                  status="warning"
                ></Banner>
              </Layout.Section>
            </>
          ) : (
            ""
          )}
          <Layout.AnnotatedSection
            title="Email from address"
            description="Pre-order confirmation emails will be sent from preorder-now@example.com
                    To send from a different email address, verify ownership using the form on the right."
          >
            <Card>
              <BlockStack gap={"200"}>
                <Text as="h1" variant="headingSm">
                  Update sender
                </Text>
                <FormLayout>
                  <TextField
                    name="sender_email_address"
                    label="Sender email address"
                    value={formik.values.sender_email_address}
                    placeholder="info@example.com"
                    onChange={(value) => {
                      formik.setFieldValue("sender_email_address", value);
                    }}
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.sender_email_address &&
                      formik.touched.sender_email_address
                        ? formik.errors.sender_email_address
                        : ""
                    }
                  />
                  <Button variant="primary" onClick={() => sendAuthMail()}>
                    Send authentication
                  </Button>
                </FormLayout>
              </BlockStack>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title="Send Test Email"
            description="Test Pre-order confirmation email will be sent from noreply@example.com"
          >
            <Card>
              <BlockStack gap={"200"}>
                <Text as="h1" variant="headingSm">
                  Test Recipient
                </Text>
                <FormLayout>
                  <TextField
                    name="recipent_email_addresss"
                    label="Test Recipient Email Address"
                    value={formik.values.recipent_email_addresss}
                    placeholder="info@example.com"
                    onChange={(value) => {
                      formik.setFieldValue("recipent_email_addresss", value);
                    }}
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    error={
                      formik.errors.recipent_email_addresss &&
                      formik.touched.recipent_email_addresss
                        ? formik.errors.recipent_email_addresss
                        : ""
                    }
                  />
                  <Button variant="primary" onClick={() => sendTestMail()}>
                    Send Test Email
                  </Button>
                </FormLayout>
              </BlockStack>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection title="Email Template">
            <Card>
              <FormLayout>
                <TextField
                  name="email_Subject"
                  label="Email subject"
                  value={formik.values.email_Subject}
                  placeholder="Your pre order product information"
                  onChange={(value) => {
                    formik.setFieldValue("email_Subject", value);
                  }}
                  autoComplete="off"
                  helpText={
                    <div>Note: A product you ordered is on pre-order</div>
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.email_Subject && formik.touched.email_Subject
                      ? formik.errors.email_Subject
                      : ""
                  }
                />
                <div className="email-editor">
                  <Label id="email_con">Email content</Label>
                  <Card>
                    {store_client_id && (
                      <EmailEditor ref={emailEditorRef} onLoad={onLoad} />
                    )}
                  </Card>
                  <div className="email-content-helptext pt-3">
                    Variables: {`{{customer_first_name}}`},{" "}
                    {`{{customer_last_name}}`}, {`{{order_number}}`},{" "}
                    {`{{product_list}}`}
                  </div>
                </div>
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection title="Advanced Settings">
            <Card>
              <BlockStack gap={"200"}>
                <Text as="h1" variant="headingSm">
                  Test Recipient
                </Text>
                <FormLayout>
                  <div>
                    <Checkbox
                      name="advanced_setting_checkbox"
                      label="BCC me on pre-order confirmation emails"
                      checked={formik.values.advanced_setting_checkbox}
                      onChange={(value) => {
                        formik.setFieldValue(
                          "advanced_setting_checkbox",
                          value
                        );
                      }}
                    />
                  </div>
                  {formik.values.advanced_setting_checkbox ? (
                    <TextField
                      label="BCC Email"
                      value={formik.values.bcc_email}
                      placeholder="sales@example.com"
                      onChange={(value) => {
                        formik.setFieldValue("bcc_email", value);
                      }}
                      autoComplete="off"
                      helpText="Default BCC email is shop email address"
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.bcc_email && formik.touched.bcc_email
                          ? formik.errors.bcc_email
                          : ""
                      }
                    />
                  ) : (
                    ""
                  )}
                </FormLayout>
              </BlockStack>
            </Card>
          </Layout.AnnotatedSection>
        </Layout>
        <div className="text-end pt-3">
          <Button
            onClick={formik.handleSubmit}
            variant="primary"
            loading={loading}
          >
            Save
          </Button>
        </div>
        <Footer />
      </Page>
      {toastMarkup}
    </>
  );
};

export default PreOrderEmail;
