import React from 'react';
import {
    LegacyCard
  } from "@shopify/polaris";
const BannerCritical = (props) => {
  return (
    <LegacyCard>
        <div className="banner">
            <div className="banner-head-critical">
                <div className="banner-heading">
                    <span className="banner-Icon-critical">
                        <svg fill="white" viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true"><path d="M10 6a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5a.75.75 0 0 1 .75-.75Z"></path><path d="M11 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"></path><path fillRule="evenodd" d="M11.237 3.177a1.75 1.75 0 0 0-2.474 0l-5.586 5.585a1.75 1.75 0 0 0 0 2.475l5.586 5.586a1.75 1.75 0 0 0 2.474 0l5.586-5.586a1.75 1.75 0 0 0 0-2.475l-5.586-5.585Zm-1.414 1.06a.25.25 0 0 1 .354 0l5.586 5.586a.25.25 0 0 1 0 .354l-5.586 5.585a.25.25 0 0 1-.354 0l-5.586-5.585a.25.25 0 0 1 0-.354l5.586-5.586Z"></path>
                        </svg>
                    </span>
                    {props.title && <h2>{props.title}</h2>}
                </div>
            </div>
            <div className="banner-content">
                <span>
                {props.description && <p className="custom-banner-description mb-2">
                    {props.description}
                    </p>}
                </span>
                {props.button && <button onClick={props.onButtonClick} className="banner-btn">{props.button}</button>}
            </div>
        </div>
    </LegacyCard>
  )
}
export default BannerCritical;