import { useState, useCallback, useEffect } from "react";
import {
  Button,
  Toast,
  Layout,
  Card,
  InlineStack,
  Text,
  Box,
} from "@shopify/polaris";
import { ApiCall, GetApiCall, ShopifyCall } from "../helpers/axios";
import { setShopData } from "../redux/commonSlice";
import { useDispatch } from "react-redux";
import app_block_enable from "../assets/images/app_block_enable.png";
import app_block_disable from "../assets/images/app_block_disable.png";
import { Modal, TitleBar, useAppBridge } from "@shopify/app-bridge-react";

const ApplicationStatus = ({ header, shop_data }) => {
  const shopify = useAppBridge();
  const dispatch = useDispatch();

  let store_client_id = shop_data?.shopInfo?.store_client_id;
  let app_status = shop_data?.shopInfo?.app_status;
  let store_name = shop_data?.shopInfo?.store_name;
  const [isEnable, setIsEnable] = useState(app_status == 0 ? true : false);
  const [activeEnable, setActiveEnable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [prevStatus, setPrevStatus] = useState(app_status == 0 ? true : false);
  const [url, seturl] = useState("");

  let showModal = () => shopify.modal.show("my-modal");
  let hideModal = () => shopify.modal.hide("my-modal");

  const toggleActive = useCallback(
    () => setActiveEnable((activeEnable) => !activeEnable),
    []
  );

  const toastMarkup = activeEnable ? (
    <Toast
      duration={5000}
      content={isEnable ? "Disable Successfully" : "Enable Successfully"}
      onDismiss={toggleActive}
    />
  ) : null;

  useEffect(() => {
    // AppStatus();
  }, [store_client_id, shop_data]);

  const handleEnable = async () => {
    setLoading(true);
    let data = {
      store_client_id: store_client_id,
      shop_data: shop_data.shopInfo,
      onb_status: shop_data.shopInfo.onboarding_status,
    };

    let res = await ApiCall("PUT", "/app-enable", data, header);
    if (res?.data?.statusCode === 200) {
      let shop_Data = Object.assign({}, shop_data.shopInfo);
      shop_Data.app_status = isEnable ? "1" : "0";
      // window.open(res?.data?.data, "_blank");
      let shopInf = {
        shopInfo: shop_Data,
        auth_token: shop_data.auth_token,
      };
      dispatch(setShopData(shopInf));
      setIsEnable(!isEnable);
      toggleActive();
      setLoading(false);
      setActive(false);
      AppStatus();
    }
  };
  const handleDisable = async () => {
    setLoading(true);
    let data = {
      store_client_id: store_client_id,
      shop_data: shop_data,
      onb_status: shop_data.onboarding_status,
    };

    let res = await ApiCall("PUT", "/app-disable", data, header);
    if (res?.data?.statusCode === 200) {
      let shop_Data = Object.assign({}, shop_data.shopInfo);
      shop_Data.app_status = isEnable ? "1" : "0";
      // window.open(res?.data?.data, "_blank");
      let shopInf = {
        shopInfo: shop_Data,
        auth_token: shop_data.auth_token,
      };
      dispatch(setShopData(shopInf));
      setIsEnable(!isEnable);
      toggleActive();
      setLoading(false);
      setActive(false);
      AppStatus();
    }
  };

  const AppStatus = async () => {
    let result = await GetApiCall(
      "GET",
      `/app_block_status_change?store_client_id=${store_client_id}`,
      header
    );
    let block_status = result.data ? result.data.data : false;
    // let theme_url = result.data ? result.data.message : "";

    // seturl(theme_url);
    console.log(result);
    return block_status;
  };

  const handleStatusCheckAndAction = async () => {
    setLoading(true);
    // console.log(url);
    let url =
      "https://" +
      store_name +
      "/admin/themes/current/editor?context=apps&activateAppId=6db3227b-acff-4966-bcdd-53204d4488c4/pre-order-block";
    window.open(url, "_blank");

    const checkStatus = async (retries = 5, delay = 5000) => {
      for (let i = 0; i < retries; i++) {
        await new Promise((resolve) => setTimeout(resolve, delay));
        const block_status = await AppStatus();
        if (block_status !== prevStatus) {
          if (block_status == true) {
            setPrevStatus(block_status);
            handleDisable();
          } else if (block_status == false) {
            setPrevStatus(block_status);
            handleEnable();
          } else {
            <Toast
              duration={5000}
              content={"Something Went Wrong"}
              onDismiss={toggleActive}
            />;
          }
          setLoading(false);
          return;
        }
      }
      setLoading(false);
    };

    checkStatus();
  };

  return (
    <>
      <Layout.AnnotatedSection
        title="Application status"
        description="Enable or disable Pre-order on your store."
      >
        <Card>
          <InlineStack blockAlign="center" align="space-between">
            <Text as="p" variant="bodyMd">
              App is now {isEnable ? "disabled" : "enabled"}.
            </Text>
            <Button
              onClick={showModal}
              variant={isEnable ? "primary" : "secondary"}
              loading={loading}
            >
              {isEnable ? "Enable" : "Disable"}
            </Button>
          </InlineStack>
        </Card>
      </Layout.AnnotatedSection>
      {toastMarkup}
      <Modal id="my-modal">
        <TitleBar
          title={`Action required: ${
            isEnable ? "Enable" : "Disable"
          } Preorder on your store`}
        >
          <button variant="primary" onClick={handleStatusCheckAndAction}>
            Go to Theme Editor
          </button>
          <button onClick={hideModal}>
            {isEnable ? "Enable later" : "Disable later"}
          </button>
        </TitleBar>
        <Box padding={"400"}>
          <div className="pre_order_app_block">
            <img
              src={isEnable ? app_block_enable : app_block_disable}
              alt="Enable app embed"
            />
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ApplicationStatus;
