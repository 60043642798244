import React from "react";
import {
  Checkbox,
  RadioButton,
  FormLayout,
  Card,
  BlockStack,
  Text,
} from "@shopify/polaris";

const CustomSettingMethod = ({
  formik,
  title,
  label,
  head,
  method_1,
  method_2,
  note,
}) => {
  return (
    <Card>
      <BlockStack gap={"200"}>
        <Text as="h1" variant="headingSm">
          {title}
        </Text>
        <FormLayout>
          <FormLayout.Group>
            <Checkbox
              label={label}
              checked={formik.values.enable_inventary}
              onChange={() =>
                formik.setFieldValue(
                  "enable_inventary",
                  !formik.values.enable_inventary
                )
              }
            />
          </FormLayout.Group>
        </FormLayout>
        {formik.values.enable_inventary && (
          <>
            <div className="stock-question">
              <h1>{head}</h1>
            </div>
            <div>
              <div className="border-bottom pb-2">
                <RadioButton
                  label={
                    <div>
                      <strong>Method 1:&nbsp;</strong>
                      <span>{method_1}</span>
                    </div>
                  }
                  checked={formik.values.method === 0}
                  id="stock1"
                  onChange={(_checked, newValue) => {
                    formik.setFieldValue("method", 0);
                  }}
                />
              </div>
              <div className="py-2">
                <RadioButton
                  label={
                    <div>
                      <strong>Method 2:&nbsp;</strong>
                      <span>{method_2}</span>
                    </div>
                  }
                  checked={formik.values.method === 1}
                  id="stock2"
                  onChange={(_checked, newValue) =>
                    formik.setFieldValue("method", 1)
                  }
                />
              </div>
            </div>
            <div className="stock-note">
              <p>
                <strong>Note:&nbsp;</strong>
                {note}
              </p>
            </div>
          </>
        )}
      </BlockStack>
    </Card>
  );
};

export default CustomSettingMethod;
