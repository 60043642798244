import { atom } from "recoil";

export const appearanceHeaderState = atom({
  key: "appearanceHeaderState",
  default: {
    headerContent: [
      "header-graphic",
      "header-title",
      "header-description",
      "product-name",
      "product-image",
      "product-price",
    ],
    files: [],
  },
});

export const appearanceEmailButtonState = atom({
  key: "appearanceEmailButtonState",
  default: {
    fontSize: "14",
    // fontFamily: "Sans-Serif",
    fontWeight: "600",
    padding: "16",
    borderRadius: "6",
    borderWidth: "0",
    textColor: "#ffffff",
    backgroundColor: "#1773B0",
    borderColor: "#000000",
    buttonText: "Email me when available",
  },
});

export const appearanceSubscribeButtonState = atom({
  key: "appearanceSubscribeButtonState",
  default: {
    fontSize: "14",
    // fontFamily: "Sans-Serif",
    fontWeight: "600",
    padding: "16",
    borderRadius: "6",
    borderWidth: "0",
    textColor: "#ffffff",
    backgroundColor: "#1773B0",
    borderColor: "#000000",
    buttonText: "SUBSCRIBE",
  },
});

export const appearanceFieldsState = atom({
  key: "appearanceFieldsState",
  default: [
    {
      type: "email",
      label: "Email",
      placeholder: "Enter your email",
      showLabel: false,
      required: false,
      showRequired: false,
    },
    {
      type: "phone",
      label: "Phone number",
      placeholder: "Enter phone number",
      showLabel: false,
      required: false,
      showRequired: false,
      defaultSelected: "pk",
    },
  ],
});

export const appearanceFooterState = atom({
  key: "appearanceFooterState",
  default: {
    showFooter: true,
    footerText: "We will send you an email once the product becomes available. Your email address will not be shared with anyone else",
  },
});
