import { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Banner,
  Select,
  TextField,
  Checkbox,
  Page,
  FormLayout,
  Button,
  Toast,
  Grid,
  Text,
  Card,
  Box,
  InlineStack,
} from "@shopify/polaris";
import { fontFamilyOptions } from "../assets/fonts/optionsList";
import Footer from "./Footer";
import { useFormik } from "formik";
import { GetApiCall, ApiCall } from "../helpers/axios";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const StylesSetting = () => {
  const navigate = useNavigate();
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const header = { authentication: shopinfo.auth_token };
  const shop_data = shopinfo.shopInfo;
  let store_client_id = shop_data.store_client_id;
  const [activeToast, setActiveToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [settingId, setSettingID] = useState(null);
  const [loading, setLoading] = useState(false);
  const [bannerStatus, setBannerStatus] = useState("1");

  const [initialState, setInitialState] = useState({
    /** styles initialstates**/
    button_font_size: "15",
    button_font_family: "",
    button_padding: "5",
    button_margin: "5",
    button_border_radius: "5",
    button_width: "1",
    button_text_color: "#000000",
    button_background_color: "#ffffff",
    button_border_color: "#119010",
    message_font_size: "15",
    message_font_family: "",
    message_padding: "5",
    message_margin: "5",
    message_border_radius: "5",
    message_width: "1",
    message_text_color: "#000000",
    message_background_color: "#ffffff",
    message_border_color: "#119010",
    badge_font_size: "15",
    badge_font_family: "",
    badge_text_color: "#000000",
    badge_background_color: "#ffffff",
    badge_shadow: false,
    badge_background_gradient: false,
    custom_css: "",
  });

  const toggleActiveToast = useCallback(
    () => setActiveToast((active) => !active),
    []
  );

  const toastMarkup = activeToast ? (
    <Toast
      content={toastMessage}
      onDismiss={toggleActiveToast}
      duration={3000}
    />
  ) : null;

  //validation start///
  const formSchema = Yup.object().shape({
    button_font_size: Yup.number()
      .typeError("Font size must be a number")
      .min(1, "Font size must be at least 1px")
      .max(100, "Font size must be at most 100px")
      .required("Font size is required"),
    button_font_family: Yup.string().required("Font family is required"),
    button_padding: Yup.number()
      .typeError("Padding must be a number")
      .min(0, "Padding must be at least 0px")
      .max(100, "Padding must be at most 100px")
      .required("Padding is required"),
    button_margin: Yup.number()
      .typeError("Margin must be a number")
      .min(0, "Margin must be at least 0px")
      .max(100, "Margin must be at most 100px")
      .required("Margin is required"),
    button_border_radius: Yup.number()
      .typeError("Border radius must be a number")
      .min(0, "Border radius must be at least 0px")
      .max(100, "Border radius must be at most 100px")
      .required("Border radius is required"),
    button_width: Yup.number()
      .typeError("Border width must be a number")
      .min(0, "Border width must be at least 0px")
      .max(20, "Border width must be at most 20px")
      .required("Border width is required"),
    button_text_color: Yup.string()
      .matches(/^#[0-9A-F]{6}$/i, "Text color must be a valid hex color code")
      .required("Text color is required"),
    button_background_color: Yup.string()
      .matches(
        /^#[0-9A-F]{6}$/i,
        "Background color must be a valid hex color code"
      )
      .required("Background color is required"),
    button_border_color: Yup.string()
      .matches(/^#[0-9A-F]{6}$/i, "Border color must be a valid hex color code")
      .required("Border color is required"),

    message_font_size: Yup.number()
      .typeError("Font size must be a number")
      .min(1, "Font size must be at least 1px")
      .max(100, "Font size must be at most 100px")
      .required("Font size is required"),
    message_font_family: Yup.string().required("Font family is required"),
    message_padding: Yup.number()
      .typeError("Padding must be a number")
      .min(0, "Padding must be at least 0px")
      .max(100, "Padding must be at most 100px")
      .required("Padding is required"),
    message_margin: Yup.number()
      .typeError("Margin must be a number")
      .min(0, "Margin must be at least 0px")
      .max(100, "Margin must be at most 100px")
      .required("Margin is required"),
    message_border_radius: Yup.number()
      .typeError("Border radius must be a number")
      .min(0, "Border radius must be at least 0px")
      .max(100, "Border radius must be at most 100px")
      .required("Border radius is required"),
    message_width: Yup.number()
      .typeError("Border width must be a number")
      .min(0, "Border width must be at least 0px")
      .max(20, "Border width must be at most 20px")
      .required("Border width is required"),
    message_text_color: Yup.string()
      .matches(/^#[0-9A-F]{6}$/i, "Text color must be a valid hex color code")
      .required("Text color is required"),
    message_background_color: Yup.string()
      .matches(
        /^#[0-9A-F]{6}$/i,
        "Background color must be a valid hex color code"
      )
      .required("Background color is required"),
    message_border_color: Yup.string()
      .matches(/^#[0-9A-F]{6}$/i, "Border color must be a valid hex color code")
      .required("Border color is required"),

    badge_font_size: Yup.number()
      .typeError("Font size must be a number")
      .min(1, "Font size must be at least 1px")
      .max(100, "Font size must be at most 100px")
      .required("Font size is required"),
    badge_font_family: Yup.string().required("Font family is required"),
    badge_text_color: Yup.string()
      .matches(/^#[0-9A-F]{6}$/i, "Text color must be a valid hex color code")
      .required("Text color is required"),
    badge_background_color: Yup.string()
      .matches(
        /^#[0-9A-F]{6}$/i,
        "Background color must be a valid hex color code"
      )
      .required("Background color is required"),
    badge_shadow: Yup.boolean(),
    badge_background_gradient: Yup.boolean(),

    custom_css: Yup.string(),
  });
  //validation end ///

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: formSchema,
    enableReinitialize: true,
    onSubmit: () => {
      settingSave();
    },
  });

  useEffect(() => {
    getAllDefaultsetting();
    getStoreData();
  }, [store_client_id, shop_data]);

  const getAllDefaultsetting = async () => {
    if (Object.keys(shop_data).length > 0) {
      let res = await GetApiCall(
        "GET",
        `/settings?store_client_id=${store_client_id}`,
        header
      );
      if (res?.data?.statusCode === 200 && res?.data?.status === "success") {
        setSettingID(res?.data?.data[0].id);
        let style_setting = JSON.parse(res?.data?.data[0]?.styles);
        if (style_setting) {
          formik.setFieldValue(
            "button_font_size",
            style_setting.style_settings_button.style_settings_button_font_size
          );
          formik.setFieldValue(
            "button_font_family",
            style_setting.style_settings_button
              .style_settings_button_font_family
          );
          formik.setFieldValue(
            "button_padding",
            style_setting.style_settings_button.style_settings_button_padding
          );
          formik.setFieldValue(
            "button_margin",
            style_setting.style_settings_button.style_settings_button_margin
          );
          formik.setFieldValue(
            "button_border_radius",
            style_setting.style_settings_button
              .style_settings_button_border_radius
          );
          formik.setFieldValue(
            "button_width",
            style_setting.style_settings_button.style_settings_button_width
          );
          formik.setFieldValue(
            "button_text_color",
            style_setting.style_settings_button.style_settings_button_text_color
          );
          formik.setFieldValue(
            "button_background_color",
            style_setting.style_settings_button
              .style_settings_button_background_color
          );
          formik.setFieldValue(
            "button_border_color",
            style_setting.style_settings_button
              .style_settings_button_border_color
          );

          formik.setFieldValue(
            "message_font_size",
            style_setting.style_settings_message
              .style_settings_message_font_size
          );
          formik.setFieldValue(
            "message_font_family",
            style_setting.style_settings_message
              .style_settings_message_font_family
          );
          formik.setFieldValue(
            "message_padding",
            style_setting.style_settings_message.style_settings_message_padding
          );
          formik.setFieldValue(
            "message_margin",
            style_setting.style_settings_message.style_settings_message_margin
          );
          formik.setFieldValue(
            "message_border_radius",
            style_setting.style_settings_message
              .style_settings_message_border_radius
          );
          formik.setFieldValue(
            "message_width",
            style_setting.style_settings_message.style_settings_message_width
          );
          formik.setFieldValue(
            "message_text_color",
            style_setting.style_settings_message
              .style_settings_message_text_color
          );
          formik.setFieldValue(
            "message_background_color",
            style_setting.style_settings_message
              .style_settings_message_background_color
          );
          formik.setFieldValue(
            "message_border_color",
            style_setting.style_settings_message
              .style_settings_message_border_color
          );

          formik.setFieldValue(
            "badge_font_size",
            style_setting.style_settings_badge.style_settings_badge_font_size
          );
          formik.setFieldValue(
            "badge_font_family",
            style_setting.style_settings_badge.style_settings_badge_font_family
          );
          formik.setFieldValue(
            "badge_text_color",
            style_setting.style_settings_badge.style_settings_badge_text_color
          );
          formik.setFieldValue(
            "badge_background_color",
            style_setting.style_settings_badge
              .style_settings_badge_background_color
          );
          formik.setFieldValue(
            "badge_shadow",
            style_setting.style_settings_badge.style_settings_badge_shadow
          );
          formik.setFieldValue(
            "badge_background_gradient",
            style_setting.style_settings_badge
              .style_settings_badge_background_gradient
          );
          formik.setFieldValue("custom_css", style_setting.custom_css);
        }
      } else if (
        res.data.status === "error" &&
        res.data.message === "Data not found!"
      ) {
        setSettingID(null);
      }
    }
  };
  const getStoreData = async () => {
    if (Object.keys(shop_data).length > 0) {
      let res = await GetApiCall(
        "GET",
        `/store_data?store_client_id=${store_client_id}`,
        header
      );
      if (res?.data?.statusCode === 200 && res?.data?.status === "success") {
        console.log(res);
        setBannerStatus(res?.data?.data[0].style_status);
      }
    }
  };

  const settingSave = async () => {
    setLoading(true);
    let data = {
      styles: {
        style_settings_button: {
          style_settings_button_font_size:
            formik.values.button_font_size !== undefined
              ? formik.values.button_font_size
              : "",
          style_settings_button_font_family:
            formik.values.button_font_family !== undefined
              ? formik.values.button_font_family
              : "",
          style_settings_button_padding:
            formik.values.button_padding !== undefined
              ? formik.values.button_padding
              : "",
          style_settings_button_margin:
            formik.values.button_margin !== undefined
              ? formik.values.button_margin
              : "",
          style_settings_button_border_radius:
            formik.values.button_border_radius !== undefined
              ? formik.values.button_border_radius
              : "",
          style_settings_button_width:
            formik.values.button_width !== undefined
              ? formik.values.button_width
              : "",
          style_settings_button_text_color:
            formik.values.button_text_color !== undefined
              ? formik.values.button_text_color
              : "",
          style_settings_button_background_color:
            formik.values.button_background_color !== undefined
              ? formik.values.button_background_color
              : "",
          style_settings_button_border_color:
            formik.values.button_border_color !== undefined
              ? formik.values.button_border_color
              : "",
        },
        style_settings_message: {
          style_settings_message_font_size:
            formik.values.message_font_size !== undefined
              ? formik.values.message_font_size
              : "",
          style_settings_message_font_family:
            formik.values.message_font_family !== undefined
              ? formik.values.message_font_family
              : "",
          style_settings_message_padding:
            formik.values.message_padding !== undefined
              ? formik.values.message_padding
              : "",
          style_settings_message_margin:
            formik.values.message_margin !== undefined
              ? formik.values.message_margin
              : "",
          style_settings_message_border_radius:
            formik.values.message_border_radius !== undefined
              ? formik.values.message_border_radius
              : "",
          style_settings_message_width:
            formik.values.message_width !== undefined
              ? formik.values.message_width
              : "",
          style_settings_message_text_color:
            formik.values.message_text_color !== undefined
              ? formik.values.message_text_color
              : "",
          style_settings_message_background_color:
            formik.values.message_background_color !== undefined
              ? formik.values.message_background_color
              : "",
          style_settings_message_border_color:
            formik.values.message_border_color !== undefined
              ? formik.values.message_border_color
              : "",
        },
        style_settings_badge: {
          style_settings_badge_font_size:
            formik.values.badge_font_size !== undefined
              ? formik.values.badge_font_size
              : "",
          style_settings_badge_font_family:
            formik.values.badge_font_family !== undefined
              ? formik.values.badge_font_family
              : "",
          style_settings_badge_text_color:
            formik.values.badge_text_color !== undefined
              ? formik.values.badge_text_color
              : "",
          style_settings_badge_background_color:
            formik.values.badge_background_color !== undefined
              ? formik.values.badge_background_color
              : "",
          style_settings_badge_shadow:
            formik.values.badge_shadow !== undefined
              ? formik.values.badge_shadow
              : "",
          style_settings_badge_background_gradient:
            formik.values.badge_background_gradient !== undefined
              ? formik.values.badge_background_gradient
              : "",
        },

        custom_css: formik.values.custom_css
          ? formik.values.custom_css.replace(/\n/g, "")
          : "",
      },
    };
    if (settingId !== null) {
      data.id = settingId;
      let res = await ApiCall("PUT", "/settings", data, header);
      if (res.data.statusCode === 200) {
        setToastMessage(res.data.message);
        toggleActiveToast();
        setLoading(false);
      }
    } else {
      data.store_client_id = store_client_id;
      let res = await ApiCall("POST", "/settings", data, header);
      if (res.data.statusCode === 200) {
        setToastMessage(res.data.message);
        toggleActiveToast();
        setLoading(false);
      }
    }
  };

  const closeBanner = async () => {
    let response = await ApiCall("PUT", "/style_status", {}, header);
    if (response.data.statusCode === 200) {
      setBannerStatus(response.data?.data?.style_status);
    }
  };

  return (
    <>
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=${formik.values.button_font_family}&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=${formik.values.message_font_family}&display=swap');
        `}
      </style>
      <Page
        backAction={{
          content: "Settings",
          onAction: () => navigate("/setting"),
        }}
        title={"Style Setting"}
      >
        <Grid>
          {bannerStatus === "0" && (
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 12, xl: 12 }}>
              {/* <Banner
                  tone="info"
                  title="Style settings below are optional"
                  onDismiss={closeBanner}
                >
                  <p>
                    Pre-order now will automatically use the styles of your
                    shopify store theme. If you'd like to override those styles,
                    you can use the style settings below.
                  </p>
                </Banner> */}
              <Banner
                tone="info"
                title="Style settings below are optional"
                onDismiss={closeBanner}
              >
                <p>Customize your PreOrder Message.</p>
              </Banner>
            </Grid.Cell>
          )}
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 6, xl: 6 }}>
            <Card>
              <FormLayout>
                <Text as="h1" variant="headingSm">
                  Button Styles
                </Text>
                <FormLayout.Group condensed>
                  <TextField
                    label="Font Size"
                    value={formik.values.button_font_size}
                    placeholder=""
                    suffix="px"
                    onChange={(value) => {
                      formik.setFieldValue("button_font_size", value);
                    }}
                    error={
                      formik.touched.button_font_size &&
                      formik.errors.button_font_size
                        ? formik.errors.button_font_size
                        : ""
                    }
                    autoComplete="off"
                  />
                  <Select
                    label="Font Family"
                    options={fontFamilyOptions}
                    onChange={(value) => {
                      formik.setFieldValue("button_font_family", value);
                    }}
                    value={formik.values.button_font_family}
                  />
                </FormLayout.Group>
                <FormLayout.Group condensed>
                  <TextField
                    label="Padding"
                    value={formik.values.button_padding}
                    placeholder=""
                    suffix="px"
                    onChange={(value) => {
                      formik.setFieldValue("button_padding", value);
                    }}
                    autoComplete="off"
                    error={
                      formik.touched.button_padding &&
                      formik.errors.button_padding
                        ? formik.errors.button_padding
                        : ""
                    }
                  />
                  <TextField
                    label="Margin"
                    value={formik.values.button_margin}
                    placeholder=""
                    suffix="px"
                    onChange={(value) => {
                      formik.setFieldValue("button_margin", value);
                    }}
                    autoComplete="off"
                    error={
                      formik.touched.button_margin &&
                      formik.errors.button_margin
                        ? formik.errors.button_margin
                        : ""
                    }
                  />
                </FormLayout.Group>
                <FormLayout.Group condensed>
                  <TextField
                    label="Border radius"
                    value={formik.values.button_border_radius}
                    placeholder=""
                    suffix="px"
                    onChange={(value) => {
                      formik.setFieldValue("button_border_radius", value);
                    }}
                    autoComplete="off"
                    error={
                      formik.touched.button_border_radius &&
                      formik.errors.button_border_radius
                        ? formik.errors.button_border_radius
                        : ""
                    }
                  />
                  <TextField
                    label="Border width"
                    value={formik.values.button_width}
                    placeholder=""
                    suffix="px"
                    onChange={(value) => {
                      formik.setFieldValue("button_width", value);
                    }}
                    autoComplete="off"
                    error={
                      formik.touched.button_width && formik.errors.button_width
                        ? formik.errors.button_width
                        : ""
                    }
                  />
                </FormLayout.Group>
                <FormLayout.Group condensed>
                  <div className="custom-input-style1">
                    <TextField
                      label="Text color"
                      value={formik.values.button_text_color}
                      placeholder=""
                      suffix={
                        <div>
                          <TextField
                            value={formik.values.button_text_color}
                            type="color"
                            onChange={(value) => {
                              formik.setFieldValue("button_text_color", value);
                            }}
                            error={
                              formik.touched.button_text_color &&
                              formik.errors.button_text_color
                                ? formik.errors.button_text_color
                                : ""
                            }
                          />
                        </div>
                      }
                      onChange={(value) => {
                        formik.setFieldValue("button_text_color", value);
                      }}
                      autoComplete="off"
                    />
                  </div>
                  <div className="custom-input-style1">
                    <TextField
                      label="Background color"
                      value={formik.values.button_background_color}
                      placeholder=""
                      suffix={
                        <div>
                          <TextField
                            value={formik.values.button_background_color}
                            type="color"
                            onChange={(value) => {
                              formik.setFieldValue(
                                "button_background_color",
                                value
                              );
                            }}
                            error={
                              formik.touched.button_background_color &&
                              formik.errors.button_background_color
                                ? formik.errors.button_background_color
                                : ""
                            }
                          />
                        </div>
                      }
                      onChange={(value) => {
                        formik.setFieldValue("button_background_color", value);
                      }}
                      autoComplete="off"
                    />
                  </div>
                </FormLayout.Group>
                <FormLayout.Group condensed>
                  <div className="row">
                    <div className="col-12 custom-input-style1">
                      <TextField
                        label="Border color"
                        value={formik.values.button_border_color}
                        placeholder=""
                        suffix={
                          <div>
                            <TextField
                              value={formik.values.button_border_color}
                              type="color"
                              onChange={(value) => {
                                formik.setFieldValue(
                                  "button_border_color",
                                  value
                                );
                              }}
                            />
                          </div>
                        }
                        onChange={(value) => {
                          formik.setFieldValue("button_border_color", value);
                        }}
                        autoComplete="off"
                        error={
                          formik.touched.button_border_color &&
                          formik.errors.button_border_color
                            ? formik.errors.button_border_color
                            : ""
                        }
                      />
                    </div>
                  </div>
                </FormLayout.Group>
              </FormLayout>
            </Card>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 6, xl: 6 }}>
            <Card>
              <div className="col-lg-12">
                <button
                  style={{
                    fontSize: `${formik.values.button_font_size}px`,
                    fontFamily: formik.values.button_font_family,
                    padding: `${formik.values.button_padding}px`,
                    margin: `${formik.values.button_margin}px`,
                    border: `${formik.values.button_width}px solid ${formik.values.button_border_color}`,
                    borderRadius: `${formik.values.button_border_radius}px`,
                    color: `${formik.values.button_text_color}`,
                    backgroundColor: `${formik.values.button_background_color}`,
                  }}
                  className="preorder_btn"
                >
                  Pre-Order
                </button>
              </div>
              <div className="col-lg-12">
                <div
                  style={{
                    maxWidth: "616px",
                    fontSize: `${formik.values.message_font_size}px`,
                    fontFamily: formik.values.message_font_family,
                    padding: `${formik.values.message_padding}px`,
                    margin: `${formik.values.message_margin}px`,
                    border: `${formik.values.message_width}px solid ${formik.values.message_border_color}`,
                    borderRadius: `${formik.values.message_border_radius}px`,
                    color: `${formik.values.message_text_color}`,
                    backgroundColor: `${formik.values.message_background_color}`,
                  }}
                >
                  <span>
                    Pre-order Now will automatically use the styles of
                    your.Shopify store theme. If you'd like to override those
                    styles, you can use the style settings below.
                  </span>
                </div>
              </div>
            </Card>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 6, xl: 6 }}>
            <Card>
              <FormLayout>
                <Text as="h1" variant="headingSm">
                  Message styles
                </Text>
                <FormLayout.Group condensed>
                  <TextField
                    label="Font Size"
                    value={formik.values.message_font_size}
                    placeholder=""
                    suffix="px"
                    onChange={(value) => {
                      formik.setFieldValue("message_font_size", value);
                    }}
                    autoComplete="off"
                  />
                  <Select
                    label="Font Family"
                    options={fontFamilyOptions}
                    onChange={(value) => {
                      formik.setFieldValue("message_font_family", value);
                    }}
                    value={formik.values.message_font_family}
                  />
                </FormLayout.Group>
                <FormLayout.Group condensed>
                  <TextField
                    label="Padding"
                    value={formik.values.message_padding}
                    placeholder=""
                    suffix="px"
                    onChange={(value) => {
                      formik.setFieldValue("message_padding", value);
                    }}
                    autoComplete="off"
                  />
                  <TextField
                    label="Margin"
                    value={formik.values.message_margin}
                    placeholder=""
                    suffix="px"
                    onChange={(value) => {
                      formik.setFieldValue("message_margin", value);
                    }}
                    autoComplete="off"
                  />
                </FormLayout.Group>
                <FormLayout.Group condensed>
                  <TextField
                    label="Border radius"
                    value={formik.values.message_border_radius}
                    placeholder=""
                    suffix="px"
                    onChange={(value) => {
                      formik.setFieldValue("message_border_radius", value);
                    }}
                    autoComplete="off"
                  />
                  <TextField
                    label="Border width"
                    value={formik.values.message_width}
                    placeholder=""
                    suffix="px"
                    onChange={(value) => {
                      formik.setFieldValue("message_width", value);
                    }}
                    autoComplete="off"
                  />
                </FormLayout.Group>
                <FormLayout.Group condensed>
                  <div className="custom-input-style1">
                    <TextField
                      label="Text color"
                      value={formik.values.message_text_color}
                      placeholder=""
                      suffix={
                        <div>
                          <TextField
                            value={formik.values.message_text_color}
                            type="color"
                            onChange={(value) => {
                              formik.setFieldValue("message_text_color", value);
                            }}
                          />
                        </div>
                      }
                      onChange={(value) => {
                        formik.setFieldValue("message_text_color", value);
                      }}
                      autoComplete="off"
                    />
                  </div>
                  <div className="custom-input-style1">
                    <TextField
                      label="Background color"
                      value={formik.values.message_background_color}
                      placeholder=""
                      suffix={
                        <div>
                          <TextField
                            value={formik.values.message_background_color}
                            type="color"
                            onChange={(value) => {
                              formik.setFieldValue(
                                "message_background_color",
                                value
                              );
                            }}
                          />
                        </div>
                      }
                      onChange={(value) => {
                        formik.setFieldValue("message_background_color", value);
                      }}
                      autoComplete="off"
                    />
                  </div>
                </FormLayout.Group>
                <FormLayout.Group condensed>
                  <div className="row">
                    <div className="col-12 custom-input-style1">
                      <TextField
                        label="Border color"
                        value={formik.values.message_border_color}
                        placeholder=""
                        suffix={
                          <div>
                            <TextField
                              value={formik.values.message_border_color}
                              type="color"
                              onChange={(value) => {
                                formik.setFieldValue(
                                  "message_border_color",
                                  value
                                );
                              }}
                            />
                          </div>
                        }
                        onChange={(value) => {
                          formik.setFieldValue("message_border_color", value);
                        }}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </FormLayout.Group>
              </FormLayout>
            </Card>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 6, xl: 6 }}>
            <Card>
              <FormLayout>
                <Text as="h1" variant="headingSm">
                  Badge styles
                </Text>
                <FormLayout.Group condensed>
                  <TextField
                    label="Font Size"
                    value={formik.values.badge_font_size}
                    placeholder=""
                    suffix="px"
                    onChange={(value) => {
                      formik.setFieldValue("badge_font_size", value);
                    }}
                    autoComplete="off"
                  />
                  <Select
                    label="Font Family"
                    options={fontFamilyOptions}
                    onChange={(value) => {
                      formik.setFieldValue("badge_font_family", value);
                    }}
                    value={formik.values.badge_font_family}
                  />
                </FormLayout.Group>
                <FormLayout.Group condensed>
                  <div className="custom-input-style1">
                    <TextField
                      label="Text color"
                      value={formik.values.badge_text_color}
                      placeholder=""
                      suffix={
                        <div>
                          <TextField
                            value={formik.values.badge_text_color}
                            type="color"
                            onChange={(value) => {
                              formik.setFieldValue("badge_text_color", value);
                            }}
                          />
                        </div>
                      }
                      onChange={(value) => {
                        formik.setFieldValue("badge_text_color", value);
                      }}
                      autoComplete="off"
                    />
                  </div>
                  <div className="custom-input-style1">
                    <TextField
                      label="Background color"
                      value={formik.values.badge_background_color}
                      placeholder=""
                      suffix={
                        <div>
                          <TextField
                            value={formik.values.badge_background_color}
                            type="color"
                            onChange={(value) => {
                              formik.setFieldValue(
                                "badge_background_color",
                                value
                              );
                            }}
                          />
                        </div>
                      }
                      onChange={(value) => {
                        formik.setFieldValue("badge_background_color", value);
                      }}
                      autoComplete="off"
                    />
                  </div>
                </FormLayout.Group>
                <FormLayout.Group condensed>
                  <Checkbox
                    label="Badge shadow"
                    checked={formik.values.badge_shadow}
                    onChange={() =>
                      formik.setFieldValue(
                        "badge_shadow",
                        !formik.values.badge_shadow
                      )
                    }
                  />
                  <Checkbox
                    label="Badge background gradient"
                    checked={formik.values.badge_background_gradient}
                    onChange={() =>
                      formik.setFieldValue(
                        "badge_background_gradient",
                        !formik.values.badge_background_gradient
                      )
                    }
                  />
                </FormLayout.Group>
              </FormLayout>
            </Card>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 6, xl: 6 }}>
            <Card>
              <TextField
                label="Custom CSS"
                value={formik.values.custom_css}
                onChange={(value) => {
                  formik.setFieldValue("custom_css", value);
                }}
                multiline={4}
                autoComplete="off"
                placeholder="CSS added here will be display in frontend"
              />
            </Card>
          </Grid.Cell>
        </Grid>
        <Box paddingBlockStart={"300"}>
          <InlineStack align="end">
            <Button
              onClick={formik.handleSubmit}
              variant="primary"
              loading={loading}
            >
              Save
            </Button>
          </InlineStack>
        </Box>
        <Footer />
        {toastMarkup}
      </Page>
    </>
  );
};

export default StylesSetting;
