import { useEffect } from "react";
import {
  Select,
  Button,
  Collapsible,
  TextField,
  Checkbox,
  Text,
  Spinner,
  Banner,
  Card,
} from "@shopify/polaris";
import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import ToggleButton from "../ToggleBtn/ToggleButton";

import { ApiCall, ShopifyCall } from "../../helpers/axios";
import PricingPlanCard from "../PricingCard/PricingPlanCard";
import { useNavigate } from "react-router-dom";
import { host } from "../../config";
const ProductSelection = ({ handleNext }) => {
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const header = { authentication: shopinfo.auth_token };
  const shop_data = shopinfo.shopInfo;
  const shop_name = shop_data.store_name;
  const store_name = shop_data.store_name;
  const [isFetching, setIsFetching] = useState(false);
  const [loadingmore, setLoadingmore] = useState(false);
  const store_client_id = shop_data.store_client_id;
  const [selected, setSelected] = useState("title");
  // const [product, setProduct] = useState('');
  const [totalpage, settotalpage] = useState(1);
  const [currpage, setCurrPage] = useState(1);
  const handleSelectChange = useCallback((value) => setSelected(value), []);
  const [cursor, setCursor] = useState(null);
  const [upgrade, setUpgrade] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(0);
  const [isChecked, setChecked] = useState(false);
  const toggleCheck = () => {
    setChecked(!isChecked);
  };

  const charge_approve = shop_data.is_charge_approve;
  const [plan_type, setPlan_type] = useState(shop_data.plan_type);

  const shop_plan = shop_data.shop_plan;
  const navigate = useNavigate();
  console.log(plan_type);
  let plan_name, plan_price_month, plan_price_year;
  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );

  const tabs = [
    {
      id: "Monthly-fitted-2",
      content: "Monthly",
      accessibilityLabel: "Monthly plan",
      panelID: "Monthly-fitted-content-2",
    },
    {
      id: "Yearly-fitted-2",
      content: "Yearly",
      panelID: "Yearly-fitted-Ccontent-2",
    },
  ];

  useEffect(() => {
    if (Number(plan_type) === 1) {
      setSelected(0);
    } else if (Number(plan_type) === 2) {
      setSelected(1);
    }
  }, [plan_type]);

  const getCharges = async (value, price) => {
    let data = {
      plan_type: value,
      shop_info: shop_data,
      price: price,
      host,
    };
    let res = await ApiCall("POST", `/recurring`, data, header);
    if (res.status == 200) {
      if (window.location.hostname !== "localhost") {
        window.parent.location.href = res?.data.data;
      }
    }
  };

  if (shop_plan === "shopify_plus" || shop_plan === "enterprise") {
    plan_name = "Shopify Plus";
    plan_price_month = "99.99";
    plan_price_year = "959.99";
  } else if (
    shop_plan === "advanced" ||
    shop_plan === "unlimited" ||
    shop_plan === "singtel_unlimited"
  ) {
    plan_name = "Shopify Advanced";

    plan_price_month = "59.99";
    plan_price_year = "575.99";
  } else if (
    shop_plan === "standard" ||
    shop_plan === "professional" ||
    shop_plan === "uafrica_professional" ||
    shop_plan === "shopify"
  ) {
    plan_name = "Shopify Standard";
    plan_price_month = "39.99";
    plan_price_year = "383.99";
  } else if (shop_plan === "basic" || shop_plan === "singtel_basic") {
    plan_name = "Shopify Basic";
    plan_price_month = "19.99";
    plan_price_year = "191.99";
  } else {
    plan_name = "Shopify Basic";
    plan_price_month = "19.99";
    plan_price_year = "191.99";
  }

  // const options = [
  //     { label: 'Title', value: 'title' },
  //     { label: 'Tags', value: 'tags' },
  //     { label: 'Type', value: 'type' },
  //     { label: 'Collections', value: 'Collections' },
  // ];
  const options = [{ label: "Title", value: "title" }];
  const [initialState, setInitialState] = useState({
    text: [],
    message: [],
    message_placement: [],
    enable_inventary: [],
    method: [],
    order_start_date: [],
    order_end_date: [],
    cart_label_text: [],
    inventory_management: [],
    inventory_quantity: [],
    variant_name: [],
    prevent_customers: [],
    order_remain_message: [],
    order_message: [],
    notify_customers: [],
    buy_now: [],
  });

  const formik = useFormik({
    initialValues: initialState,
    enableReinitialize: true,
    onSubmit: (values) => {
      productSave(values);
    },
  });

  const [searchQuery, setSearchQuery] = useState("");
  // const handleSearch = useCallback(
  //     (newValue) => setSearchQuery(newValue),
  //     [],
  // )

  const productSave = async (values) => {
    for (let i = 0; i < products.length; i++) {
      let productData = products[i];

      if (productData.isChecked && Object.keys(productData).length > 0) {
        let product_data = {
          image: productData.featuredImage ? productData.featuredImage : "",
          tags: productData?.tags?.length
            ? productData.tags
                .map((tag) => `${tag.replace('"', '\\"')}`)
                .join(", ")
            : "",
          title: productData.name,
        };
        console.log(product_data);
        let id_url = productData.id;
        var id = id_url.split("/").pop();
        var data = {
          store_client_id: store_client_id,
          product_id: id,
          product_title: productData.name,
          product_data: product_data,
          is_variant_product: 1,
        };
        let productVariants = productData?.varients;
        let status_arr = [];
        let obj = {};
        if (productVariants && productVariants.length > 0) {
          productVariants.map((item, index) => {
            console.log("item", item);
            let variant_id_url = item.id;
            var variant_id = variant_id_url.split("/").pop();

            obj[variant_id] = {
              is_enable: item.isChecked ? "1" : "0",
              product_inventory_quantity:
                values.inventory_quantity !== undefined
                  ? item.inventory_quantity
                  : "",
              product_inventory_management:
                values.inventory_management !== undefined
                  ? item.inventory_management
                  : "",
              product_variant_name:
                item.name !== undefined ? item.name : "Default Title",
            };
            obj[variant_id].is_custome_setting = "0";
            status_arr.push(obj[variant_id].is_enable);
          });

          data.setting_data = obj;
          console.log("data", data);

          if (status_arr.includes("0") && status_arr.includes("1")) {
            data.product_variant_status = "2";
          } else if (status_arr.includes("1")) {
            data.product_variant_status = "1";
          } else if (status_arr.includes("0")) {
            data.product_variant_status = "0";
          }
        }
        // console.log(obj);
        let res = await ApiCall("POST", "/single-product", data, header);

        if (res.data.statusCode === 200) {
          console.log(res.data);
          handleNext(res.data);
        }
      }
    }
    console.log("handelAddProduct");
  };
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + " ...";
    }
    return text;
  };

  // const handleChecked = (index) => {
  //   let xproducts = JSON.parse(JSON.stringify(products));
  //   let isParentChecked = xproducts[index].isChecked;
  //   xproducts[index].isChecked = !isParentChecked;
  //   xproducts[index].varients?.forEach((varient) => {
  //     varient.isChecked = isParentChecked ? false : true;
  //   });
  //   console.log(xproducts);
  //   setProducts(xproducts);
  // };

  const handleChecked = (productId) => {
    let xproducts = JSON.parse(JSON.stringify(products));
    let productIndex = xproducts.findIndex((p) => p.id === productId);
    let isParentChecked = xproducts[productIndex].isChecked;
    xproducts[productIndex].isChecked = !isParentChecked;

    // Update the child variants as well
    xproducts[productIndex].varients?.forEach((varient) => {
      varient.isChecked = isParentChecked ? false : true;
    });

    setProducts(xproducts);
  };
  const handleUpgrade = (index) => {
    console.log("first");
    setUpgrade(true);
  };

  const handlefree = (index) => {
    console.log("handlefree");
    setUpgrade(false);
  };

  // const handleChildChecked = (index, varientIndex) => {
  //   let xproducts = JSON.parse(JSON.stringify(products));
  //   let isChecked = xproducts[index].varients[varientIndex].isChecked;
  //   xproducts[index].varients[varientIndex].isChecked = !isChecked;

  //   let isAnyChildChecked = false;
  //   xproducts[index].varients.map((varient) => {
  //     if (varient.isChecked) {
  //       isAnyChildChecked = true;
  //     }
  //   });

  //   xproducts[index].isChecked = isAnyChildChecked;

  //   setProducts(xproducts);
  // };
  const handleChildChecked = (productId, varientId) => {
    let xproducts = JSON.parse(JSON.stringify(products));
    let productIndex = xproducts.findIndex((p) => p.id === productId);
    let varientIndex = xproducts[productIndex].varients.findIndex(
      (v) => v.id === varientId
    );

    let isChecked = xproducts[productIndex].varients[varientIndex].isChecked;
    xproducts[productIndex].varients[varientIndex].isChecked = !isChecked;

    // Check if any child is selected, then select parent accordingly
    let isAnyChildChecked = xproducts[productIndex].varients.some(
      (varient) => varient.isChecked
    );
    xproducts[productIndex].isChecked = isAnyChildChecked;

    setProducts(xproducts);
  };
  const GetProductList = async (value, page) => {
    setLoading(true);
    setLoadingmore(true);
    let res = "";
    let data = {
      store_client_id: shop_data.store_client_id,
      shop_info: shop_data,
      shop: store_name,
      page_query: "after",
      limit: 10,
      cursor: cursor,
      type: "onb",
    };
    page = page ? page : 1;
    if (value && value.length >= 3) {
      data.search_type = selected;
      setSearchQuery(value);
      res = await ShopifyCall(
        "POST",
        `/search-product?search_key=${value}`,
        data,
        header
      );
    } else {
      res = await ShopifyCall("POST", `/product-api-list`, data, header);
    }
    if (res?.data?.status === "success" && res?.data?.statusCode === 200) {
      console.log(res?.data);
      settotalpage(res?.data?.data?.total_page);
      setHasNextPage(res?.data?.data?.is_next_page);
      setCurrPage(page);
      setCursor(res?.data?.data?.nxt_page_cursor);

      const productsData = res?.data?.data?.products;
      // Map the fetched data to match the existing state structure
      const mappedProducts = productsData.map((product) => ({
        id: product.id,
        isChecked: false,
        name: product.title,
        tags: Object.values(product?.tags),
        featuredImage: product.featuredImage?.originalSrc
          ? product.featuredImage.originalSrc
          : "",
        varients: product.variants.edges.map((variant) => ({
          id: variant.node.id,
          name: variant.node.title,
          quantity: variant.node.availableForSale
            ? "Available"
            : "Out of Stock",
          price: `$${variant.node.price}`,
          isChecked: false,
          inventory_management: variant.node.inventoryPolicy,
          inventory_quantity: variant.node.inventoryQuantity,
          variant_image: variant?.node?.image?.url,
        })),
      }));
      setLoading(false);
      setLoadingmore(false);
      setProducts((prevProducts) => {
        // Create a set of existing product IDs for quick lookup
        const existingProductIds = new Set(
          prevProducts.map((product) => product.id)
        );

        // Filter the mapped products to include only those that are not already in the state
        const newProducts = mappedProducts.filter(
          (product) => !existingProductIds.has(product.id)
        );

        // Append new products to the existing state
        return [...prevProducts, ...newProducts];
      });
    }
  };

  const [products, setProducts] = useState([]);

  const [accord, setAccord] = useState("");
  const handleToggle = (e, id) => {
    if (e.target.tagName !== "INPUT") {
      if (accord == id) {
        setAccord("");
      } else {
        setAccord(id);
      }
    }
  };

  const filterProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  // console.log(products)
  const handelSearch = () => {
    GetProductList(searchQuery);
  };
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    GetProductList();
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div className="p-4 d-grid gap-2 w-100">
      {upgrade ? (
        <div className="p-4 w-100">
          <div className="m-auto">
            <div className="d-flex gap-2 justify-content-center mb-2">
              <p
                className={`${
                  !isChecked ? "active-plan-is" : "inactive-plan-is"
                }`}
              >
                MONTHLY
              </p>
              <ToggleButton
                isChecked={isChecked}
                handleCheckChange={toggleCheck}
              />
              <p className={isChecked ? "active-plan-is" : "inactive-plan-is"}>
                YEARLY
              </p>
            </div>
            {isChecked && (
              <div className="m-auto" style={{ maxWidth: "352px" }}>
                <PricingPlanCard
                  tagTitle="Free for 14 days"
                  planType={plan_name}
                  trileTime="Free for 14 days"
                  planPrice={plan_price_year}
                  planDuration="year"
                  feature1="Unlimited Products"
                  feature2="Unlimited orders"
                  feature3="Unlimited Emails"
                  feature4="Preorder Badges on Collections"
                  feature5="Special Discount for Products"
                  feature6="Mixed Cart Warning"
                  planButton="Upgrade"
                  onButtonClick={() => getCharges("ANNUAL", plan_price_year)}
                />
              </div>
            )}
            {!isChecked && (
              <div className="m-auto" style={{ maxWidth: "352px" }}>
                <PricingPlanCard
                  tagTitle="Free for 14 days"
                  planType={plan_name}
                  trileTime="Free for 14 days"
                  planPrice={plan_price_month}
                  planDuration="month"
                  feature1="Unlimited Products"
                  feature2="Unlimited orders"
                  feature3="Unlimited Emails"
                  feature4="Preorder Badges on Collections"
                  feature5="Special Discount for Products"
                  feature6="Mixed Cart Warning"
                  planButton="Upgrade"
                  onButtonClick={() =>
                    getCharges("EVERY_30_DAYS", plan_price_month)
                  }
                />
              </div>
            )}
          </div>
          <a href="#" onClick={handlefree}>
            Continue as free
          </a>
        </div>
      ) : (
        <>
          {products.filter((p) => p.isChecked).length > 1 &&
            Number(plan_type) === 0 && (
              <Banner
                tone="warning"
                title="Product limit exceeded"
                secondaryAction={{
                  content: "Upgrade now",
                  onAction: handleUpgrade,
                }}
              >
                <p>
                  Your product limit is 1, you selected more than 1 product, to
                  continue upgrade your plan.
                </p>
              </Banner>
            )}
          <div className="d-flex gap-2">
            <Select
              options={options}
              onChange={handleSelectChange}
              value={selected}
            />
            <div className="width-100p">
              <TextField
                value={searchQuery}
                placeholder=""
                type="serach"
                onChange={(value) => {
                  setSearchQuery(value);
                  GetProductList(value);
                }}
              />
            </div>
            <Button variant="primary" onClick={handelSearch}>
              Search
            </Button>
          </div>
          <Card>
            <div
              className="product-list-scroll"
              style={{ width: "100%", height: "395px", borderRadius: "12px" }}
            >
              {loading && (
                <div className="py-2 d-flex justify-content-center align-items-center h-100">
                  <Spinner accessibilityLabel="Spinner example" size="large" />
                </div>
              )}
              {filterProducts.map((product) => (
                <div key={product.id}>
                  <div
                    onClick={(e) => handleToggle(e, product.id)}
                    className={`d-flex align-items-center justify-content-between product-single-parent-list ${
                      product.isChecked ? "product-single-list-active" : ""
                    }`}
                  >
                    <div className="d-flex align-items-center gap-2">
                      <Checkbox
                        checked={product.isChecked}
                        onChange={(e) => handleChecked(product.id)}
                      />

                      <div className="d-flex align-items-center px-2">
                        <Text variant="bodyMd" fontWeight="bold" as="h3">
                          {truncateText(product.name, 50)}
                        </Text>
                      </div>
                    </div>
                    {product.varients && product.varients.length < 2 && (
                      <div className="d-flex gap-4">
                        <Text variant="bodyMd" as="h3">
                          {product.varients[0].inventory_quantity} Available
                        </Text>
                        <Text variant="bodyMd" as="h3">
                          {product.varients[0].price}
                        </Text>
                      </div>
                    )}
                    {product.varients && product.varients.length > 1 && (
                      <svg
                        style={{ height: "20px", width: "20px" }}
                        viewBox="0 0 20 20"
                        className={`Polaris-Icon__Svg ${
                          accord === product.id ? "accord-icon-rotate" : ""
                        }`}
                        focusable="false"
                        aria-hidden="true"
                      >
                        <path d="M10 14a.997.997 0 0 1-.707-.293l-5-5a.999.999 0 1 1 1.414-1.414l4.293 4.293 4.293-4.293a.999.999 0 1 1 1.414 1.414l-5 5a.997.997 0 0 1-.707.293z"></path>
                      </svg>
                    )}
                  </div>
                  {product.varients && product.varients.length > 1 ? (
                    <Collapsible
                      open={accord == product.id}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      {product.varients &&
                        product.varients.map((varient) => (
                          <div
                            key={varient.id}
                            className={`d-flex align-items-center justify-content-between product-single-child-list ${
                              varient.isChecked
                                ? "product-single-list-active"
                                : ""
                            }`}
                          >
                            <div className="d-flex align-items-center gap-2 w-100">
                              <Checkbox
                                checked={varient.isChecked}
                                onChange={() =>
                                  handleChildChecked(product.id, varient.id)
                                }
                              />

                              <div className="d-flex align-items-center px-2 justify-content-between w-100">
                                <Text
                                  variant="bodyMd"
                                  fontWeight="bold"
                                  as="h3"
                                >
                                  {varient.name}
                                </Text>
                                <div className="d-flex gap-4">
                                  <Text variant="bodyMd" as="h3">
                                    {varient.inventory_quantity} Available
                                  </Text>
                                  <Text variant="bodyMd" as="h3">
                                    {varient.price}
                                  </Text>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </Collapsible>
                  ) : (
                    <Collapsible>
                      {product.varients &&
                        product.varients.map((varient) => (
                          <div
                            key={varient.id}
                            className={`d-flex align-items-center justify-content-between product-single-child-list ${
                              varient.isChecked
                                ? "product-single-list-active"
                                : ""
                            }`}
                          >
                            <div className="d-flex align-items-center gap-2 w-100">
                              <Checkbox
                                checked={varient.isChecked}
                                onChange={() =>
                                  handleChildChecked(product.id, varient.id)
                                }
                              />

                              <div className="d-flex align-items-center px-2 justify-content-between w-100">
                                <Text
                                  variant="bodyMd"
                                  fontWeight="bold"
                                  as="h3"
                                >
                                  {varient.name}
                                </Text>
                                <div className="d-flex gap-4">
                                  <Text variant="bodyMd" as="h3">
                                    {varient.inventory_quantity} Available
                                  </Text>
                                  <Text variant="bodyMd" as="h3">
                                    {varient.price}
                                  </Text>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </Collapsible>
                  )}
                  {isFetching && (
                    <div className="py-2 d-flex justify-content-center align-items-center h-100">
                      <Spinner
                        accessibilityLabel="Loading more products"
                        size="small"
                      />
                    </div>
                  )}
                  {filterProducts.length == 0 && (
                    <div className="py-2 d-flex justify-content-center align-items-center h-100">
                      No product found
                    </div>
                  )}
                </div>
              ))}
              <div className="p-2">
                {hasNextPage ? (
                  <Button secondary fullWidth onClick={GetProductList}>
                    Load more
                  </Button>
                ) : null}
                {loadingmore ? (
                  <div className="py-2 d-flex justify-content-center align-items-center h-100">
                    <Spinner
                      accessibilityLabel="Loading more products"
                      size="small"
                    />
                  </div>
                ) : null}
              </div>
              {filterProducts.length == 0 && (
                <div className="py-2 d-flex justify-content-center align-items-center h-100">
                  No product found
                </div>
              )}
            </div>
          </Card>

          <div className="text-end">
            {products.filter((p) => p.isChecked).length !== 1 &&
            Number(plan_type) === 0 ? (
              <Button
                disabled
                variant="primary"
                onClick={() => formik.handleSubmit()}
              >
                Add
              </Button>
            ) : (
              <Button variant="primary" onClick={() => formik.handleSubmit()}>
                Add
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ProductSelection;
