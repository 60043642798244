import React, { useCallback, useEffect, useState } from "react";
import {
  InlineStack,
  IndexFilters,
  IndexFiltersMode,
  IndexTable,
  Card,
  Page,
  Text,
  Thumbnail,
  useSetIndexFiltersMode,
  Pagination,
} from "@shopify/polaris";
import { useNavigate } from "react-router-dom";
import { GetApiCall, ApiCall } from "../helpers/axios";
import { useSelector } from "react-redux";
import noimg from "../assets/images/noimg.jpg";

const OutOfStock = () => {
  let navigate = useNavigate();
  let handleBackAction = () => {
    navigate("/back-in-stock");
  };
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  let store_client_id = shop_data.store_client_id;
  const header = {
    authentication: shopinfo?.auth_token,
    Authorization: shopinfo?.session_token,
  };
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [hasPreviousPage, setHasPreviousPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(0);
  const [cursor, setCursor] = useState(null);

  const sortOptions = [
    { label: "Order", value: "order asc", directionLabel: "Ascending" },
    { label: "Order", value: "order desc", directionLabel: "Descending" },
    { label: "Customer", value: "customer asc", directionLabel: "A-Z" },
    { label: "Customer", value: "customer desc", directionLabel: "Z-A" },
    { label: "Date", value: "date asc", directionLabel: "A-Z" },
    { label: "Date", value: "date desc", directionLabel: "Z-A" },
    { label: "Total", value: "total asc", directionLabel: "Ascending" },
    { label: "Total", value: "total desc", directionLabel: "Descending" },
  ];
  const [sortSelected, setSortSelected] = useState(["order asc"]);
  const { mode, setMode } = useSetIndexFiltersMode(IndexFiltersMode.Filtering);
  const [queryValue, setQueryValue] = useState("");

  const handleFiltersQueryChange = useCallback(
    (value) => {
      setQueryValue(value);
        handleSubmissionsAll(value);
     console.log("queryValue", value);
    },
    []
  );

  const [tableData, setTableData] = useState([]);
  const resourceName = {
    singular: "product",
    plural: "products",
  };

  // const dummyData = [
  //   {
  //     id: "1",
  //     name: "product 1",
  //     imgUrl: "https://picsum.photos/id/1/200",
  //   },
  //   {
  //     id: "2",
  //     name: "product 2",
  //     imgUrl: "https://picsum.photos/id/2/200",
  //   },
  //   {
  //     id: "3",
  //     name: "product 3",
  //     imgUrl: "https://picsum.photos/id/3/200",
  //   },
  // ];

  useEffect(() => {
    // setTableData(dummyData);
    handleSubmissionsAll("", "");
  }, []);

  let handleSubmissionsAll = async (value, query) => {
    try {
      setLoading(true);
      let queryValue = value ? value : null;
      let data = {
        store_client_id: store_client_id,
        search: queryValue,
        cursor: cursor,
        page_query: query ? query : "after",
      };
      console.log("data", data);
      let res = await ApiCall("POST", "/out_of_stock_products", data, header);
      if (res.status === 200) {
        setTableData(res?.data?.data?.products || []);
        setTotalPages(res?.data?.data?.total_page || 1); // Update total pages
        setHasPreviousPage(res?.data?.data?.is_previous_page);
        setHasNextPage(res?.data?.data?.is_next_page);
        setCursor(res?.data?.data?.nxt_page_cursor);
        console.log("res", res?.data?.data?.total_page);
      } else {
        setTableData([]);
      }
    } catch (error) {
      setTableData([]);
      console.log("error", error);
    }
    setLoading(false);
    console.log("loading", loading);
  };

  return (
    <Page
      title="Out of stock product"
      backAction={{ onAction: handleBackAction }}
    >
      <Card padding={"0"}>
        <IndexFilters
          // sortOptions={sortOptions}
          // sortSelected={sortSelected}
          // onSort={setSortSelected}
          queryValue={queryValue}
          queryPlaceholder="Search by product title"
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={() => setQueryValue("")}
          tabs={[]}
          filters={[]}
          mode={mode}
          setMode={setMode}
        />
        <IndexTable
          resourceName={resourceName}
          itemCount={tableData.length}
          selectable={false}
          headings={[{ title: "Products" }]}
        >
          {tableData.map((item, index) => (
            <IndexTable.Row id={item.id} key={item.id} position={index}>
              <IndexTable.Cell>
                <InlineStack blockAlign="center" gap={"500"}>
                  <Thumbnail source={item?.featuredImage ? item?.featuredImage?.originalSrc : noimg} size="small" />
                  <Text as="p" variant="bodyMd">
                    {item?.title}
                  </Text>
                </InlineStack>
              </IndexTable.Cell>
            </IndexTable.Row>
          ))}
          <div className="single-product-pagination">
            {hasNextPage || hasPreviousPage ? (
              <Pagination
                hasPrevious={hasPreviousPage}
                previousTooltip="Previous"
                onPrevious={() => {
                  handleSubmissionsAll("","before");
                }}
                hasNext={hasNextPage}
                nextTooltip="Next"
                onNext={() => {
                  handleSubmissionsAll("","after");
                }}
              />
            ) : (
              ""
            )}
          </div>
        </IndexTable>
      </Card>
    </Page>
  );
};

export default OutOfStock;
