import React, { useEffect, useState , useCallback } from "react";
import {
  Card,
  Button,
  Checkbox,
  InlineGrid,
  InlineStack,
  Icon,
  Page,
  ResourceItem,
  ResourceList,
  Text,
  TextField,
  BlockStack,
  Thumbnail,
  Toast,

} from "@shopify/polaris";
import { SearchIcon, DeleteIcon, ImageIcon } from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";
import { useAppBridge } from "@shopify/app-bridge-react";
import { GetApiCall, ApiCall } from "../helpers/axios";
import { useSelector } from "react-redux";
const AddSubscriber = () => {
  const shopify = useAppBridge();
  let navigate = useNavigate();
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  let store_client_id = shop_data.store_client_id;
  const header = { authentication: shopinfo?.auth_token , Authorization:shopinfo?.session_token };
  const [loading, setLoading] = useState(false);
  let [email, setEmail] = useState("");
  let [phone, setPhone] = useState("");
  let [notification, setNotification] = useState(false);
  let [product, setProduct] = useState(null);
  let [formError, setFormError] = useState({});
  const [activeEnable, setActiveEnable] = useState(false);
  const [message, setMessage] = useState("Data Already Exsists");
  let removeProductVariant = (variantIndex) => {
    console.log("deleted variant index", variantIndex);
    let xProduct = JSON.parse(JSON.stringify(product));
    xProduct.variants.splice(variantIndex, 1);
    setProduct(xProduct);
  };

  let handleInputFocus = async () => {
    const selected = await shopify.resourcePicker({ type: "product" });
    if (selected) setProduct(selected[0]);
  };
  const toggleActive = useCallback(
    () => setActiveEnable((activeEnable) => !activeEnable),
    []
  );
  let checkFormValidation = () => {
    let error = {};
    if (!emailIsValid(email)) error.email = "Email is invalid";
    if (!product) error.product = "Product is required";
    if (email == "") error.email = "Email is required";
    setFormError(error);
    if (isEmptyObject(error)) {
      return true;
    } else {
      return false;
    }
  };

  // util functions
  function isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
  }
  function emailIsValid(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const toastMarkup = activeEnable ? (
    <Toast
      duration={5000}
      content={message}
      onDismiss={toggleActive}
    />
  ) : null;

  let handleSubmit = async () => {
    setLoading(true);
    let isValid = checkFormValidation();
    if (isValid) {
      let data = {
        store_client_id,
        email,
        phone,
        product,
        notification,
      };

      let res = await ApiCall("POST", "/add_submissions", data, header);
        if (res.data.statusCode === 200) {
        console.log(res);
        setMessage(res.data.message);
        toggleActive();
        setLoading(false);

        }else{
          setMessage(res.data.message);
          toggleActive();
          setLoading(false);
          
        }

      console.log(data);
    }
  };

  return (
    <Page
      title="Add subscriber"
      backAction={{ onAction: () => navigate("/back-in-stock") }}
    >
      <BlockStack gap={"300"}>
        <InlineGrid
          gap={"300"}
          alignItems="start"
          columns={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
        >
          <Card roundedAbove="xs">
            <BlockStack gap={"400"}>
              <Text as="h1" variant="headingSm">
                Choose product
              </Text>
              <BlockStack gap={"300"}>
                <TextField
                  prefix={<Icon source={SearchIcon} />}
                  placeholder="Search"
                  onFocus={handleInputFocus}
                  error={formError?.product}
                />
                {product && product.hasOnlyDefaultVariant ? (
                  <Card padding={"300"} roundedAbove="xs">
                    <InlineStack gap={"300"} blockAlign="center" wrap={false}>
                      <div style={{ flexShrink: 0 }}>
                        <Thumbnail
                          source={
                            product.images.length > 0
                              ? product.images[0].originalSrc
                              : ImageIcon
                          }
                          size="small"
                        />
                      </div>
                      <Text as="p" variant="bodyLg">
                        {product?.title}
                      </Text>
                      <div style={{ marginLeft: "auto", flexShrink: 0 }}>
                        <Button
                          variant="tertiary"
                          icon={DeleteIcon}
                          onClick={() => setProduct(null)}

                        />
                      </div>
                    </InlineStack>
                  </Card>
                ) : (
                  product?.variants?.map((variant, variantIndex) => (
                    <Card padding={"300"} roundedAbove="xs" key={variantIndex}>
                      <InlineStack gap={"300"} blockAlign="center" wrap={false}>
                        <div style={{ flexShrink: 0 }}>
                          <Thumbnail
                            source={
                              variant.image
                                ? variant.image.originalSrc
                                : ImageIcon
                            }
                            size="small"
                          />
                        </div>
                        <Text as="p" variant="bodyLg">
                          {variant?.displayName}
                        </Text>
                        <div style={{ marginLeft: "auto", flexShrink: 0 }}>
                          <Button
                            variant="tertiary"
                            icon={DeleteIcon}
                            onClick={() => removeProductVariant(variantIndex)}
                          />
                        </div>
                      </InlineStack>
                    </Card>
                  ))
                )}
              </BlockStack>
              <Checkbox
                label="Send product registration notifications to customers."
                checked={notification}
                onChange={(value, id) => setNotification(value)}
              />
            </BlockStack>
          </Card>
          <Card roundedAbove="xs">
            <BlockStack gap={"400"}>
              <Text as="h1" variant="headingSm">
                Subscriber information
              </Text>
              <BlockStack gap={"200"}>
                <TextField
                  label="Email address"
                  type="email"
                  value={email}
                  onChange={setEmail}
                  placeholder="Enter customer email address"
                  error={formError?.email}
                />
                <TextField
                  label="Phone Number"
                  type="email"
                  value={phone}
                  onChange={setPhone}
                  placeholder="(201) 555-0123s"
                />
              </BlockStack>
            </BlockStack>
          </Card>
        </InlineGrid>
        <InlineStack align="end">
          <Button variant="primary" onClick={handleSubmit} loading={loading}>
            Add
          </Button>
        </InlineStack>
      </BlockStack>
      {toastMarkup}
    </Page>
  );
};

export default AddSubscriber;
