import { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Button,
  InlineStack,
  Page,
  Text,
  TextField,
  BlockStack,
  Card,
} from "@shopify/polaris";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, TitleBar, useAppBridge } from "@shopify/app-bridge-react";

const SubmissionDetail = () => {
  const shopify = useAppBridge();
  let navigate = useNavigate();
  let { id } = useParams();

  let [data, setData] = useState({});
  let [note, setNote] = useState("");
  let [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  let [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  let handleBackAction = () => {
    navigate("/back-in-stock");
  };

  let dummyData = {
    email: "abc@abc.com",
    productName: "swiss knife",
  };

  useEffect(() => {
    setData(dummyData);
  }, []);

  let handleSendEmailClick = () => {
    toggleEmailModal();
  };

  let handleDeleteSubmissionClick = () => {
    toggleDeleteModal();
  };

  let toggleDeleteModal = () => {
    shopify.modal.toggle("delete-modal");
  };
  let toggleEmailModal = () => {
    shopify.modal.toggle("email-modal");
  };
  let handleDeleteSubmit = () => {
    console.log("handleDeleteSubmit");
  };
  let handleEmailSubmit = () => {
    console.log("handleEmailSubmit");
  };

  let handleSubmit = () => {
    console.log(note);
  };

  return (
    <Page
      title={data.email}
      subtitle="Nov 25 at 02:26 AM"
      backAction={{ onAction: handleBackAction }}
      actionGroups={[
        {
          title: "More actions",
          actions: [
            {
              content: "Send email",
              onAction: handleSendEmailClick,
            },
            {
              content: "Delete submission",
              destructive: true,
              onAction: handleDeleteSubmissionClick,
            },
          ],
        },
      ]}
    >
      <BlockStack gap={"3"}>
        <Card padding={"0"}>
          <Box borderColor="border" borderBlockEndWidth="1" padding={"4"}>
            <InlineStack gap={"4"}>
              <BlockStack gap={"4"}>
                <Text as="h3" variant="headingSm">
                  User email
                </Text>
                <Text as="h3" variant="headingSm">
                  Product
                </Text>
                <Text as="h3" variant="headingSm">
                  SKU
                </Text>
                <Text as="h3" variant="headingSm">
                  Status
                </Text>
                <Text as="h3" variant="headingSm">
                  Locale
                </Text>
                <Text as="h3" variant="headingSm">
                  Subscribed date
                </Text>
                <Text as="h3" variant="headingSm">
                  Email last send
                </Text>
                <Text as="h3" variant="headingSm">
                  Email last subscribed
                </Text>
              </BlockStack>
              <BlockStack gap={"4"}>
                <Text as="p" variant="bodyMd">
                  {data.email}
                </Text>
                <Text as="p" variant="bodyMd">
                  {data.productName}
                </Text>
                <Text as="p" variant="bodyMd">
                  SKU
                </Text>
                <Text as="p" variant="bodyMd">
                  <Badge>Pending</Badge>
                </Text>
                <Text as="p" variant="bodyMd">
                  Default
                </Text>
                <Text as="p" variant="bodyMd">
                  Nov 25 at 02:26 AM
                </Text>
                <Text as="p" variant="bodyMd">
                  Nov 25 at 02:26 AM
                </Text>
                <Text as="p" variant="bodyMd">
                  -
                </Text>
              </BlockStack>
            </InlineStack>
          </Box>
          <Box padding={"4"}>
            <TextField
              type="text"
              label="Note"
              value={note}
              onChange={setNote}
              multiline={5}
            />
          </Box>
        </Card>
        <InlineStack align="end">
          <Button variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </InlineStack>
      </BlockStack>

      {/* delete confirmation modal */}
      <Modal id="delete-modal">
        <TitleBar title="Delete submission?">
          <button variant="primary" onClick={handleDeleteSubmit}>
            Delete
          </button>
          <button onClick={toggleDeleteModal}>Cancel</button>
        </TitleBar>
        <Box padding={"400"}>
          All submissions data also are removed. Once deleted, it cannot be
          completed. Are you sure you want to delete?
        </Box>
      </Modal>

      {/* email confirmation modal */}
      <Modal id="email-modal">
        <TitleBar title="Are you sure you want to send email?">
          <button variant="primary" onClick={handleEmailSubmit}>
            Send
          </button>
          <button onClick={toggleEmailModal}>Cancel</button>
        </TitleBar>
        <Box padding={"400"}>
          You're about to send out an email, are you sure you want to send it
          out?
        </Box>
      </Modal>
    </Page>
  );
};

export default SubmissionDetail;
