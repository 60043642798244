import { useState , useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Button,
  ChoiceList,
  InlineStack,
  Page,
  Text,
  BlockStack,
  Grid,
} from "@shopify/polaris";
import { GetApiCall, ApiCall } from "../helpers/axios";
import { useSelector } from "react-redux";
const BackInStock_setting_location = () => {
  let navigate = useNavigate();

  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  let store_client_id = shop_data.store_client_id;
  const header = { authentication: shopinfo?.auth_token , Authorization:shopinfo?.session_token };
  const [settingId, setSettingID] = useState(null);
  const [loading, setLoading] = useState(false);

  const [selectedLocations, setSelectedLocations] = useState([]);

  useEffect(()=>{
    getBackInStockSettings();
  },[])

  const getBackInStockSettings = async () => {
  
    let result = await GetApiCall(
      "GET",
      `/back_in_stock?store_client_id=${store_client_id}`,
      header
    );
    if(result?.data?.statusCode === 200 && result?.data?.status === "success"){
      console.log(result?.data?.data?.activity_update)
      setSettingID(result?.data?.data?.id);
    }
  };

  let handleBackAction = () => {
    navigate("/back-in-stock");
  };

  let handleSubmit = async() => {
    console.log(selectedLocations);
    setLoading(true);
    let data_back_in_stock = {
      locations:selectedLocations,
      id:settingId
    }
    let res = await ApiCall("PUT", "/update_back_in_stock", data_back_in_stock, header);
    if (res.data.statusCode === 200) {
    console.log(res);
    setLoading(false);
    }
  };

  return (
    <Page title="Locations" backAction={{ onAction: handleBackAction }}>
      <BlockStack gap={"300"}>
        <Grid gap={"400"}>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 2, lg: 3, xl: 3 }}>
            <Text as="h1" variant="headingSm">
              Shopify locations
            </Text>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 9, xl: 9 }}>
            <Card roundedAbove="xs">
              <BlockStack gap={"300"}>
                <h1 className="h-16">Choose locations</h1>
                <Text as="p" variant="bodyLg">
                  Choose the locations to connect to Back in Stock that are able
                  to fulfill online orders. Notifications will only be sent if
                  there is an available variant in the connected locations. The
                  connected locations do not impact the visibility of the button
                  in the storefront.
                </Text>
                <ChoiceList
                  allowMultiple
                  choices={[
                    {
                      label: "My Custom Location",
                      value: "custom_location",
                    },
                    {
                      label: "Shop location",
                      value: "shop_location",
                    },
                    {
                      label: "Snow City Warehouse",
                      value: "snow_city_warehouse",
                    },
                  ]}
                  selected={selectedLocations}
                  onChange={setSelectedLocations}
                />
              </BlockStack>
            </Card>
          </Grid.Cell>
        </Grid>
        <InlineStack align="end">
          <Button loading={loading} variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </InlineStack>
      </BlockStack>
    </Page>
  );
};

export default BackInStock_setting_location;
