import { useState , useEffect } from "react";
import {
  BlockStack,
  Box,
  Button,
  Card,
  Checkbox,
  Collapsible,
  Text,
  TextField,
} from "@shopify/polaris";
import { useRecoilState } from "recoil";
import { appearanceFooterState } from "../../../../store/appearanceSettingAtom";

const Footer = ({ isOpen, toggle }) => {
  let [data, setData] = useRecoilState(appearanceFooterState);

  useEffect(() => {
    if (data) {
      setShowFooter(data.showFooter ?? false);
      setFooterText(data.footerText ?? "");
    }
  }, [data]);

  const [showFooter, setShowFooter] = useState(data?.showFooter || false);
  const [footerText, setFooterText] = useState(data?.footerText || "");

  const handleUpdate = () => {
    setData({
      showFooter,
      footerText,
    });
  };

  useEffect(() => {
    handleUpdate();
  }, [showFooter, footerText]);

  return (
    <Card padding={"0"} roundedAbove="xs">
      <Button
        variant="tertiary"
        fullWidth
        size="large"
        textAlign="left"
        disclosure={isOpen ? "up" : "down"}
        onClick={toggle}
      >
        <Box paddingBlock={"150"} paddingInline={"100"}>
          <h1 className="h-16">Footer</h1>
        </Box>
      </Button>
      <Collapsible
        open={isOpen}
        transition={{ duration: "500ms", timingFunction: "ease-in-out" }}
      >
        <Box paddingInline={"400"} paddingBlockEnd={"400"}>
          <BlockStack gap={"400"}>
            <Text as="p" variant="bodyMd">
              Change the footer content here
            </Text>
            <BlockStack gap={"200"}>
              <TextField
                multiline={3}
                value={footerText}
                onChange={(value) => setFooterText(value)}
              />
              <Checkbox
                label="Show footer"
                checked={showFooter}
                onChange={(value) => setShowFooter(value)}
              />
            </BlockStack>
          </BlockStack>
        </Box>
      </Collapsible>
    </Card>
  );
};

export default Footer;