import { useEffect, useState } from "react";
import {
  Card,
  Button,
  Grid,
  InlineGrid,
  InlineStack,
  Page,
  Text,
  Thumbnail,
  BlockStack,
  Badge,
} from "@shopify/polaris";
import { ImageIcon } from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";
import { ApiCall, GetApiCall } from "../../../helpers/axios";
import { useSelector } from "react-redux";


const SettingPage = () => {

  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  let store_client_id = shop_data.store_client_id;
  const store_name = shop_data.store_name;
  const header = { authentication: shopinfo?.auth_token , Authorization:shopinfo?.session_token };
  const [settingId, setSettingID] = useState(null);
  const [loading, setLoading] = useState(false);


  let navigate = useNavigate();
  let [isEnable, setIsEnable] = useState(false);
  let toggleStatus = () => {
    setIsEnable(!isEnable);
  };

  let data = [
    {
      title: "Appearance",
      description: "Search for products to make available for.",
      imgUrl: ImageIcon,
      url: "/back-in-stock/setting/appearance",
    },
    {
      title: "Conditions",
      description: "Search for products to make available for.",
      imgUrl: ImageIcon,
      url: "/back-in-stock/setting/conditions",
    },
    {
      title: "Delivery policy",
      description: "Search for products to make available for.",
      imgUrl: ImageIcon,
      url: "/back-in-stock/setting/delivery-policy",
    },
    {
      title: "Campaigns",
      description: "Search for products to make available for.",
      imgUrl: ImageIcon,
      url: "/back-in-stock/setting/campaigns",
    },
    {
      title: "Activity updates",
      description: "Search for products to make available for.",
      imgUrl: ImageIcon,
      url: "/back-in-stock/setting/activity",
    },
    {
      title: "Locations",
      description: "Search for products to make available for.",
      imgUrl: ImageIcon,
      url: "/back-in-stock/setting/locations",
    },
    {
      title: "Additional",
      description: "Search for products to make available for.",
      imgUrl: ImageIcon,
      url: "/back-in-stock/setting/additional",
    },
  ];
  useEffect(()=>{
    getBackInStockSettings();
  },[])

  const getBackInStockSettings = async () => {
    console.log("back in stock status");

    let result = await GetApiCall(
      "GET",
      `/back_in_stock?store_client_id=${store_client_id}`,
      header
    );
    if(result?.data?.statusCode === 200 && result?.data?.status === "success"){
      let is_enable = result.data.data.status === 1 ? true : false;
      setSettingID(result?.data?.data?.id);
      setIsEnable(is_enable)
      console.log("isEnable 1", isEnable)
    }
    console.log("isEnable 2",isEnable)
  };

  const updateBackInStockSettings = async () => {
    setLoading(true);
    let is_enable = isEnable === true ? 0 : 1 ;

    let data = {
      back_in_stock_status:is_enable,
      id:settingId
    }
    let res = await ApiCall("PUT", "/update_back_in_stock", data, header);
    if (res.data.statusCode === 200) {
    console.log("updateBackInStockSettings ",res.data);
    setIsEnable(!isEnable);
    setLoading(false);
    }


  }
  return (
    <Page>
      <BlockStack gap={"400"}>
        <Grid>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 2, lg: 3, xl: 3 }}>
            <BlockStack gap={"100"}>
              <Text as="h1" variant="headingSm">
                Status
              </Text>
              <Text as="h1" variant="bodySm">
                Enable or disable back in stock in your app
              </Text>
            </BlockStack>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 9, xl: 9 }}>
            <Card padding={"400"} roundedAbove="xs">
              <InlineStack
                align="space-between"
                blockAlign="center"
                gap={"200"}
              >
                <Text as="h1" variant="headingSm">
                  Back in stock is now{" "}
                  {isEnable ? <Badge>enabled</Badge> : <Badge>disabled</Badge>}
                </Text>
                <Button
                  variant={isEnable ? "secondary" : "primary"}
                  onClick={updateBackInStockSettings}
                  loading={loading}
                >
                  {isEnable ? "Disable" : "Enable"}
                </Button>
              </InlineStack>
            </Card>
          </Grid.Cell>
        </Grid>
        <Card roundedAbove="xs" padding={{ xs: "0", sm: "400" }}>
          <InlineGrid
            gap={"300"}
            columns={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
          >
            {data.map((item, index) => (
              <div
                className="back-in-stock-setting-div"
                onClick={() => navigate(item.url)}
                key={index}
              >
                <InlineStack gap={"400"} wrap={false} blockAlign="center">
                  <Thumbnail source={item.imgUrl} size="small" />
                  <BlockStack gap={"100"}>
                    <Text as="h1" variant="headingSm">
                      {item.title}
                    </Text>
                    <Text as="p" variant="bodySm">
                      {item.description}
                    </Text>
                  </BlockStack>
                </InlineStack>
              </div>
            ))}
          </InlineGrid>
        </Card>
      </BlockStack>
    </Page>
  );
};

export default SettingPage;
