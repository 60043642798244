import { useState , useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Button,
  Grid,
  InlineStack,
  Page,
  Text,
  TextField,
  BlockStack,
} from "@shopify/polaris";
import { GetApiCall, ApiCall } from "../helpers/axios";
import { useSelector } from "react-redux";
const BackInStock_setting_additional = () => {
  let [css, setCss] = useState("");

  let navigate = useNavigate();

  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  let store_client_id = shop_data.store_client_id;
  const header = { authentication: shopinfo?.auth_token , Authorization:shopinfo?.session_token };
  const [settingId, setSettingID] = useState(null);
  const [loading, setLoading] = useState(false);

  let handleBackAction = () => {
    navigate("/back-in-stock");
  };

  let handleSubmit = async() => {
    setLoading(true);
    let data_back_in_stock = {
      additional:css,
      id:settingId
    }
    let res = await ApiCall("PUT", "/update_back_in_stock", data_back_in_stock, header);
    if (res.data.statusCode === 200) {
    console.log(res);
    setLoading(false);
    }
  };
  
  useEffect(()=>{
    getBackInStockSettings();
  },[])

  const getBackInStockSettings = async () => {
  
    let result = await GetApiCall(
      "GET",
      `/back_in_stock?store_client_id=${store_client_id}`,
      header
    );
    if(result?.data?.statusCode === 200 && result?.data?.status === "success"){
      console.log(result?.data?.data?.additional)
      setCss(result?.data?.data?.additional)
      setSettingID(result?.data?.data?.id);
    }
  };
  return (
    <Page title="Additional" backAction={{ onAction: handleBackAction }}>
      <BlockStack gap={"300"}>
        <Grid>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 2, lg: 3, xl: 3 }}>
            <Text as="h1" variant="headingSm">
              Additional CSS
            </Text>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 9, xl: 9 }}>
            <Card roundedAbove="xs">
              <TextField
                type="text"
                label="Custom CSS"
                value={css}
                onChange={setCss}
                multiline={6}
              />
            </Card>
          </Grid.Cell>
        </Grid>
        <InlineStack align="end">
          <Button loading={loading} variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </InlineStack>
      </BlockStack>
    </Page>
  );
};

export default BackInStock_setting_additional;
