import {
  Button,
  Select,
  ButtonGroup,
  Page,
  Layout,
  FormLayout,
  SkeletonBodyText,
  Text,
  SkeletonDisplayText,
  Box,
  Card,
  BlockStack,
} from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetApiCall, ApiCall } from "./../helpers/axios";
import { useNavigate } from "react-router-dom";
import { Modal, TitleBar, useAppBridge } from "@shopify/app-bridge-react";
import app_block_enable from "../assets/images/app_block_enable.png";
import app_block_disable from "../assets/images/app_block_disable.png";

const InstallUninstall = () => {
  const shopify = useAppBridge();
  const navigate = useNavigate();
  /* IW0081 - Store Data */
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  const store_client_id = shop_data.store_client_id;
  const header = { authentication: shopinfo.auth_token };
  const handleModalChange = () => shopify.modal.toggle("my-modal");
  const [insUn, setInsun] = useState([]);
  /* iw0081 - Themes */
  const [ready, setReady] = useState(false);
  const [themes, setthemes] = useState([]);

  /* iw0081 - banner */
  const [banner, setBanner] = useState(false);

  /* iw0081 - selectbox */
  const [selected, setSelected] = useState();
  const handleSelectChange = useCallback(
    (value) => setSelected(parseInt(value)),
    []
  );

  /* iw0081 - get all themes */
  let getThemes = async (store_client_id) => {
    if (Object.keys(shop_data).length > 0) {
      let res = await GetApiCall(
        "POST",
        `/themes?store_client_id=${store_client_id}`,
        header
      );
      if (res?.data?.statusCode === 200 && res?.data?.status === "success") {
        setthemes(res?.data?.data?.themes);
        setSelected(res?.data?.data?.first_theme_id);
        setReady(true);
        if (res?.data?.data?.themes.length == 0) {
          setBanner(true);
        }
      }
    }
  };

  const InstallUninstalHandle = async (action) => {
    let shopData = Object.assign({}, shop_data);
    shopData.store_theme_id = selected;
    let apiData = {
      store_client_id: store_client_id,
      shop_data: shopData,
    };
    let res = await ApiCall("GET", "/app_block_status", apiData, header);
    let block_status = res.data ? res.data.data : false;
    let blockAction = action === 1 ? "activateAppId" : "appEmbed";
    if (block_status === false) {
      window.open(
        `https://${shop_data.store_name}/admin/themes/${selected}/editor?context=apps&template=${selected}&${blockAction}=6db3227b-acff-4966-bcdd-53204d4488c4/pre-order-block`,
        "_blank"
      );
    }
    handleModalChange();
  };

  useEffect(() => {
    getThemes(store_client_id);
  }, [store_client_id]);

  const handleClose = () => {
    handleModalChange();
  };

  return (
    <>
      <Page
        backAction={{
          content: "Settings",
          onAction: () => navigate("/setting"),
        }}
        title={"Install/Uninstall"}
      >
        <Layout>
          <Layout.Section title="Theme">
            <Card>
              {ready ? (
                themes.length > 0 ? (
                  <>
                    <FormLayout>
                      <FormLayout.Group condensed>
                        <Select
                          label="Use settings for theme"
                          options={themes}
                          onChange={handleSelectChange}
                          value={selected}
                        />
                      </FormLayout.Group>
                    </FormLayout>
                    <div className="mt-1">
                      Theme not here? Try "Default" or{" "}
                      <a
                        href="mailto:support@conversionbat.com"
                        target="_blank"
                        className="theme_install"
                      >
                        ask us to add your theme.
                      </a>
                    </div>
                    <div className="pt-3">
                      <p>
                        The buttons below will install and uninstall Pre-order.
                        Now from your published theme.
                      </p>
                      <div className="pt-2">
                        <ButtonGroup>
                          <Button
                            variant="primary"
                            onClick={() => {
                              handleModalChange();
                              setInsun(1);
                            }}
                          >
                            Install
                          </Button>
                          <Button
                            outline
                            onClick={() => {
                              handleModalChange();
                              setInsun(0);
                            }}
                          >
                            Uninstall
                          </Button>
                        </ButtonGroup>
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    No themes available or only one theme install on store
                  </div>
                )
              ) : (
                <BlockStack gap={"400"}>
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText />
                </BlockStack>
              )}
            </Card>
          </Layout.Section>
        </Layout>
        <Modal id="my-modal" variant="large">
          <TitleBar
            title={`Action required: ${
              insUn ? "Install" : "Uninstall"
            } Preorder on your store`}
          >
            <button
              variant="primary"
              onClick={() => InstallUninstalHandle(insUn ? 1 : 0)}
            >
              Go to Theme Editor
            </button>
            <button onClick={handleClose}>
              {insUn ? "Install later" : "Uninstall later"}
            </button>
          </TitleBar>
          <Box padding={"400"}>
            <div className="pre_order_app_block">
              <img
                src={insUn ? app_block_enable : app_block_disable}
                alt="Enable app embed"
              />
            </div>
          </Box>
        </Modal>
      </Page>
    </>
  );
};

export default InstallUninstall;
