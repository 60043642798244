import { useState , useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Button,
  Grid,
  InlineStack,
  Page,
  Text,
  TextField,
  BlockStack,
  ChoiceList,
  Box,
  Icon,
  Badge,
} from "@shopify/polaris";
import { SearchIcon, XIcon } from "@shopify/polaris-icons";
import TagsPicker from "../components/back-in-stock/tags-picker";
import { useAppBridge } from "@shopify/app-bridge-react";
import { GetApiCall, ApiCall } from "../helpers/axios";
import { useSelector } from "react-redux";
const BackInStock_setting_conditions = () => {
  const shopify = useAppBridge();
  let navigate = useNavigate();
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  let store_client_id = shop_data.store_client_id;
  const header = { authentication: shopinfo?.auth_token , Authorization:shopinfo?.session_token };
  const [settingId, setSettingID] = useState(null);
  const [loading, setLoading] = useState(false);
  let handleBackAction = () => {
    navigate("/back-in-stock");
  };

  let [trackQty, setTrackQty] = useState([]);
  let [inventoryPolicy, setInventoryPolicy] = useState([]);
  let [showBackInStock, setShowBackInStock] = useState([]);
  let [showProducts, setShowProducts] = useState([]);
  let [showTags, setShowTags] = useState([]);
  let [showCollections, setShowCollections] = useState([]);
  let [hideTags, setHideTags] = useState([]);
  let [hideCollections, setHideCollections] = useState([]);

  let handleProductsInputFocus = async () => {
    let selectionIds = showProducts.map((product) => ({
      id: product.id,
    }));
    const selected = await shopify.resourcePicker({
      type: "product",
      multiple: true,
      selectionIds: selectionIds,
    });
    if (selected) setShowProducts(selected);
  };
  useEffect(()=>{
    getBackInStockSettings();
  },[])

  const getBackInStockSettings = async () => {
  
    let result = await GetApiCall(
      "GET",
      `/back_in_stock?store_client_id=${store_client_id}`,
      header
    );
    if(result?.data?.statusCode === 200 && result?.data?.status === "success"){
      // console.log(result?.data?.data?.conditions)
      const conditions = JSON.parse(result?.data?.data?.conditions);
      if (conditions) {
      console.log(conditions.showBackInStock)

        setTrackQty([conditions.trackQty || ""]); // Assuming the response might have the `trackQty` value
        setInventoryPolicy([conditions.inventoryPolicy || ""]);
        setShowBackInStock([conditions.showBackInStock?.selected || ""]);
        setHideTags(conditions.hideBackInStock?.tags || []);
        setHideCollections(conditions.hideBackInStock?.collections || []);
        setShowProducts(conditions.showBackInStock?.products || []);
        setShowTags(conditions.showBackInStock?.tags || [] );
        setShowCollections(conditions.showBackInStock?.collections || []);
      console.log(conditions.showBackInStock?.data)

      }
      setSettingID(result?.data?.data?.id);
    }
  };
  let removeProduct = (productIndex) => {
    setShowProducts(showProducts.toSpliced(productIndex, 1));
  };

  let handleCollectionInputFocus = async (workFor) => {
    let selectionIds = [];
    if (workFor == "show") {
      selectionIds = showCollections.map((collection) => ({
        id: collection.id,
      }));
    }
    if (workFor == "hide") {
      selectionIds = hideCollections.map((collection) => ({
        id: collection.id,
      }));
    }

    const selected = await shopify.resourcePicker({
      type: "collection",
      multiple: true,
      selectionIds: selectionIds,
    });

    if (selected && workFor == "show") setShowCollections(selected);
    if (selected && workFor == "hide") setHideCollections(selected);
  };

  let removeCollection = (workFor, collectionIndex) => {
    if (workFor == "show") {
      setShowCollections(showCollections.toSpliced(collectionIndex, 1));
    }
    if (workFor == "hide") {
      setHideCollections(hideCollections.toSpliced(collectionIndex, 1));
    }
  };

  let handleSubmit = async() => {
    setLoading(true);
    let showData = [];
    if (showBackInStock[0] == "products") showData = showProducts;
    if (showBackInStock[0] == "tags") showData = showTags;
    if (showBackInStock[0] == "collections") showData = showCollections;
    let data = {
      trackQty: trackQty[0],
      inventoryPolicy: inventoryPolicy[0],
      showBackInStock: {
        selected: showBackInStock[0],
        [showBackInStock[0]]: showData,  // Dynamically set the property based on showBackInStock[0]
      },
      hideBackInStock: {
        tags: hideTags,
        collections: hideCollections,
      },
    };
    let data_back_in_stock = {
      conditions:data,
      id:settingId
    }
    let res = await ApiCall("PUT", "/update_back_in_stock", data_back_in_stock, header);
    if (res.data.statusCode === 200) {
    console.log(res);
    setLoading(false);
    }
    console.log(data);
  };

  return (
    <Page title="Conditions" backAction={{ onAction: handleBackAction }}>
      <BlockStack gap={"300"}>
        <Grid>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 2, lg: 3, xl: 3 }}>
            <Text as="h1" variant="headingSm">
              With track quantity
            </Text>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 9, xl: 9 }}>
            <Card roundedAbove="xs">
              <ChoiceList
                title={
                  <h1 className="h-16">
                    Display only out-of-stock products with track quantity.
                  </h1>
                }
                choices={[
                  { label: "Track quantity", value: "track" },
                  { label: "Untrack quantity", value: "untrack" },
                  { label: "Both", value: "both" },
                ]}
                selected={trackQty}
                onChange={setTrackQty}
              />
            </Card>
          </Grid.Cell>
        </Grid>
        <Grid>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 2, lg: 3, xl: 3 }}>
            <Text as="h1" variant="headingSm">
              With inventory policy
            </Text>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 9, xl: 9 }}>
            <Card roundedAbove="xs">
              <ChoiceList
                title={
                  <h1 className="h-16">
                    Display only out-of-stock products with an active inventory
                    policy.
                  </h1>
                }
                choices={[
                  {
                    label: "Stop selling when the product is out of stock",
                    value: "stop",
                  },
                  {
                    label: "Continue selling when the product is out of stock",
                    value: "continue",
                  },
                  { label: "Both", value: "both" },
                ]}
                selected={inventoryPolicy}
                onChange={setInventoryPolicy}
              />
            </Card>
          </Grid.Cell>
        </Grid>
        <Grid>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 2, lg: 3, xl: 3 }}>
            <Text as="h1" variant="headingSm">
              Show back in stock
            </Text>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 9, xl: 9 }}>
            <Card roundedAbove="xs">
              <ChoiceList
                title={
                  <h1 className="h-16">
                    Display products marked as back in stock.
                  </h1>
                }
                choices={[
                  {
                    label: "All products out of stock ",
                    value: "all",
                  },
                  {
                    label: "Choose products",
                    value: "products",
                    helpText: showBackInStock.includes("products") && (
                      <BlockStack gap="200">
                        <TextField
                          type="search"
                          prefix={<Icon source={SearchIcon} />}
                          placeholder="Search"
                          onFocus={handleProductsInputFocus}
                        />
                        {showProducts.length > 0 && (
                          <InlineStack gap={"200"}>
                            {showProducts.map((product, productIndex) => {
                              return (
                                <Badge key={productIndex}>
                                  <InlineStack blockAlign="center" gap={"100"}>
                                    <p>{product.title}</p>
                                    <Button
                                      icon={XIcon}
                                      loading={loading}
                                      variant="plain"
                                      onClick={() =>
                                        removeProduct(productIndex)
                                      }
                                    />
                                  </InlineStack>
                                </Badge>
                              );
                            })}
                          </InlineStack>
                        )}
                      </BlockStack>
                    ),
                  },
                  {
                    label: "Tag(s)",
                    value: "tags",
                    helpText: showBackInStock.includes("tags") && (
                      <TagsPicker
                        placeholder="Enter tags"
                        tags={showTags}
                        onChange={setShowTags}
                      />
                    ),
                  },
                  {
                    label: "Collection(s)",
                    value: "collections",
                    helpText: showBackInStock.includes("collections") && (
                      <BlockStack gap="200">
                        <TextField
                          type="search"
                          prefix={<Icon source={SearchIcon} />}
                          placeholder="Search"
                          onFocus={() => handleCollectionInputFocus("show")}
                        />
                        {showCollections.length > 0 && (
                          <InlineStack gap={"200"}>
                            {showCollections.map(
                              (collection, collectionIndex) => {
                                return (
                                  <Badge key={collectionIndex}>
                                    <InlineStack
                                      blockAlign="center"
                                      gap={"100"}
                                    >
                                      <p>{collection.title}</p>
                                      <Button
                                        icon={XIcon}
                                        variant="plain"
                                        onClick={() =>
                                          removeCollection(
                                            "show",
                                            collectionIndex
                                          )
                                        }
                                      />
                                    </InlineStack>
                                  </Badge>
                                );
                              }
                            )}
                          </InlineStack>
                        )}
                      </BlockStack>
                    ),
                  },
                ]}
                selected={showBackInStock}
                onChange={setShowBackInStock}
              />
            </Card>
          </Grid.Cell>
        </Grid>
        <Grid>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 2, lg: 3, xl: 3 }}>
            <Text as="h1" variant="headingSm">
              Hide back in stock
            </Text>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 9, xl: 9 }}>
            <Card roundedAbove="xs">
              <BlockStack gap={"300"}>
                <h1 className="h-16">Hide products marked as back in stock.</h1>
                <Text as="p" variant="bodyMd">
                  Select products you don’t want to display as back in stock
                  based on tag and collection.
                </Text>
                <TagsPicker
                  label="Tag(s)"
                  placeholder="Enter tags"
                  tags={hideTags}
                  onChange={setHideTags}
                />
                <TextField
                  label="Collection(s)"
                  placeholder="Search"
                  prefix={<Icon source={SearchIcon} />}
                  onFocus={() => handleCollectionInputFocus("hide")}
                  helpText={
                    hideCollections.length > 0 && (
                      <Box paddingBlockStart={"100"}>
                        <InlineStack gap={"200"}>
                          {hideCollections.map(
                            (collection, collectionIndex) => {
                              return (
                                <Badge key={collectionIndex}>
                                  <InlineStack blockAlign="center" gap={"100"}>
                                    <p>{collection.title}</p>
                                    <Button
                                      icon={XIcon}
                                      variant="plain"
                                      onClick={() =>
                                        removeCollection(
                                          "hide",
                                          collectionIndex
                                        )
                                      }
                                    />
                                  </InlineStack>
                                </Badge>
                              );
                            }
                          )}
                        </InlineStack>
                      </Box>
                    )
                  }
                />
              </BlockStack>
            </Card>
          </Grid.Cell>
        </Grid>
        <InlineStack align="end">
          <Button loading={loading} variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </InlineStack>
      </BlockStack>
    </Page>
  );
};

export default BackInStock_setting_conditions;
