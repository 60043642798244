import {
  Card,
  Button,
  Divider,
  Grid,
  InlineStack,
  Page,
  Text,
  BlockStack,
  ChoiceList,
  TextField,
  Select,
} from "@shopify/polaris";
import React, { useState , useEffect  } from "react";
import { useNavigate } from "react-router-dom";
import { GetApiCall, ApiCall } from "../helpers/axios";
import { useSelector } from "react-redux";
const BackInStock_setting_delivery_policy = () => {
  let navigate = useNavigate();
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  let store_client_id = shop_data.store_client_id;
  const header = { authentication: shopinfo?.auth_token , Authorization:shopinfo?.session_token };
  const [settingId, setSettingID] = useState(null);
  const [loading, setLoading] = useState(false);
  let [notifyBackInStock, setNotifyBackInStock] = useState([]);
  let [notifyRestock, setNotifyRestock] = useState(false);
  let [inventoryPolicy, setInventoryPolicy] = useState([]);
  let [notificationRate, setNotificationRate] = useState("1");
  let [numberOfCustomers, setNumberOfCustomers] = useState("10");
  let [notificationOrder, setNotificationOrder] = useState("old");
  let [deliveryInterval, setDeliveryInterval] = useState("1");
  let [minQtySend, setMinQtySend] = useState("1");

  let handleBackAction = () => {
    navigate("/back-in-stock");
  };
  useEffect(()=>{
    getBackInStockSettings();
  },[])

  const getBackInStockSettings = async () => {
  
    let result = await GetApiCall(
      "GET",
      `/back_in_stock?store_client_id=${store_client_id}`,
      header
    );
    if(result?.data?.statusCode === 200 && result?.data?.status === "success"){
      const deliveryPolicy = JSON.parse(result?.data?.data?.delivery_policy);

      if (deliveryPolicy) {
        setNotifyBackInStock(deliveryPolicy.notifyBackInStock || []);
        setNotifyRestock(deliveryPolicy.notifyRestock || false);
        setInventoryPolicy(deliveryPolicy.inventoryPolicy || []);
        setNotificationRate(deliveryPolicy.notificationRate || "1");
        setNumberOfCustomers(deliveryPolicy.numberOfCustomers || "10");
        setNotificationOrder(deliveryPolicy.notificationOrder || "old");
        setDeliveryInterval(deliveryPolicy.deliveryInterval || "1");
        setMinQtySend(deliveryPolicy.minQtySend || "1");
      }
      
      setSettingID(result?.data?.data?.id);
    }
  };
  let handleSubmit = async() => {
    setLoading(true);
    let data = {
      notifyBackInStock,
      notifyRestock,
      inventoryPolicy,
      notificationRate,
      numberOfCustomers,
      notificationOrder,
      deliveryInterval,
      minQtySend,
    };
    let data_back_in_stock = {
      delivery_policy:data,
      id:settingId
    }
    let res = await ApiCall("PUT", "/update_back_in_stock", data_back_in_stock, header);
    if (res.data.statusCode === 200) {
    console.log(res);
    setLoading(false);
    }
    console.log(data);
  };

  return (
    <Page title="Delivery policy" backAction={{ onAction: handleBackAction }}>
      <BlockStack gap={"300"}>
        <Grid>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 2, lg: 3, xl: 3 }}>
            <Text as="h1" variant="headingSm">
              Notify on back in stock
            </Text>
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 9, xl: 9 }}>
            <Card roundedAbove="xs">
              <BlockStack gap={"300"}>
                <Text as="h1" variant="headingMd">
                  Notify customers when a product is available again in stock.
                </Text>
                <ChoiceList
                  choices={[
                    {
                      label:
                        "I will personally notify customers when the product is restocked.",
                      value: "self",
                    },
                    { label: "Send notification automatically", value: "auto" },
                  ]}
                  selected={notifyBackInStock}
                  onChange={setNotifyBackInStock}
                />
              </BlockStack>
            </Card>
          </Grid.Cell>
          {notifyBackInStock[0] == "auto" && (
            <>
              <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 2, lg: 3, xl: 3 }}>
                <Text as="h1" variant="headingSm">
                  Status
                </Text>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 9, xl: 9 }}>
                <Card>
                  <InlineStack
                    gap={"400"}
                    align="space-between"
                    blockAlign="center"
                  >
                    <Text as="h1" variant="headingSm">
                      Will notify all subscribers once a product is restocked
                    </Text>
                    <Button
                      onClick={() => setNotifyRestock(!notifyRestock)}
                      variant={notifyRestock ? "secondary" : "primary"}
                      loading={loading}
                    >
                      {notifyRestock ? "Disable" : "Enable"}
                    </Button>
                  </InlineStack>
                </Card>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 2, lg: 3, xl: 3 }}>
                <Text as="h1" variant="headingSm">
                  With inventory policy
                </Text>
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 4, lg: 9, xl: 9 }}>
                <Card>
                  <BlockStack gap={"300"}>
                    <Text as="h1" variant="headingSm">
                      Display only products that are out of stock and have an
                      inventory policy in place.
                    </Text>
                    <ChoiceList
                      allowMultiple
                      choices={[
                        {
                          label: "Send email notification automatically",
                          value: "email-automatically",
                        },
                        {
                          label:
                            'Enable notifications for products set to "Pre-order" status.',
                          value: "notification-on-pre-order-status",
                        },
                        {
                          label:
                            'When the product is enabled, allows sending notifications "Continue selling when out of stock"',
                          value: "continue-selling-out-of-stock",
                        },
                        {
                          label:
                            "When the product is draft, allow sending notification",
                          value: "draft-product-notification",
                        },
                      ]}
                      selected={inventoryPolicy}
                      onChange={setInventoryPolicy}
                    />
                    {notifyRestock && (
                      <>
                        {inventoryPolicy.includes(
                          "continue-selling-out-of-stock"
                        ) ? (
                          <TextField
                            type="number"
                            min={0}
                            label="Number of customers to notify per send."
                            helpText={
                              <>
                                <Text as="p" variant="bodyMd">
                                  Notify x customers with each notification
                                  delivery.
                                </Text>
                                <Text as="p" variant="bodyMd">
                                  Note: This applies only when "Continue selling
                                  when out of stock" is enabled for the product.
                                </Text>
                              </>
                            }
                            value={numberOfCustomers}
                            onChange={setNumberOfCustomers}
                          />
                        ) : (
                          <TextField
                            type="number"
                            min={0}
                            label="Notification rate"
                            helpText={
                              <>
                                <Text as="p" variant="bodyMd">
                                  Set the rate to 1 to match notifications sent
                                  with the available inventory quantity.
                                </Text>
                                <Text as="p" variant="bodyMd">
                                  Note: This applies only when "Continue selling
                                  when out of stock" is disabled for the
                                  product.
                                </Text>
                              </>
                            }
                            value={notificationRate}
                            onChange={setNotificationRate}
                          />
                        )}
                        <Divider borderColor="border-hover" />
                        <Select
                          label="Notification order"
                          options={[
                            {
                              label: "Oldest notification first",
                              value: "old",
                            },
                            {
                              label: "Newest notification first",
                              value: "new",
                            },
                          ]}
                          value={notificationOrder}
                          onChange={setNotificationOrder}
                        />
                        <Divider borderColor="border-hover" />
                        <Select
                          label="Delivery interval"
                          options={[
                            {
                              label: "1 hour(s)",
                              value: "1",
                            },
                            {
                              label: "2 hour(s)",
                              value: "2",
                            },
                            {
                              label: "4 hour(s)",
                              value: "4",
                            },
                            {
                              label: "8 hour(s)",
                              value: "8",
                            },
                            {
                              label: "12 hour(s)",
                              value: "12",
                            },
                            {
                              label: "24 hour(s)",
                              value: "24",
                            },
                          ]}
                          value={deliveryInterval}
                          onChange={setDeliveryInterval}
                        />
                        <Divider borderColor="border-hover" />
                        <TextField
                          type="number"
                          min={0}
                          label="Minimum quantity to send"
                          helpText={
                            inventoryPolicy.includes(
                              "continue-selling-out-of-stock"
                            ) ? (
                              <Text as="p" variant="bodyMd">
                                Set the minimum to x to notify customers only
                                when there are at least x items available in
                                stock. Or leave it blank if no minimum limit
                              </Text>
                            ) : (
                              <>
                                <Text as="p" variant="bodyMd">
                                  Set the minimum to x to notify customers only
                                  when there are at least x items available in
                                  stock.
                                </Text>
                                <Text as="p" variant="bodyMd">
                                  Note: This applies only when "Continue selling
                                  when out of stock" is disabled for the
                                  product.
                                </Text>
                              </>
                            )
                          }
                          value={minQtySend}
                          onChange={setMinQtySend}
                        />
                      </>
                    )}
                  </BlockStack>
                </Card>
              </Grid.Cell>
            </>
          )}
        </Grid>
        <InlineStack align="end">
          <Button loading={loading} variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </InlineStack>
      </BlockStack>
    </Page>
  );
};

export default BackInStock_setting_delivery_policy;
