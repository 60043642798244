import { useState , useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Button,
  InlineStack,
  Page,
  Text,
  BlockStack,
  Bleed,
  Tabs,
  Box,
  Badge,
  TextField,
} from "@shopify/polaris";
import { GetApiCall, ApiCall } from "../helpers/axios";
import { useSelector } from "react-redux";
const BackInStock_setting_campaigns = () => {
  let navigate = useNavigate();
  const shopinfo = useSelector((state) => state.commonReducer.shop_data);
  const shop_data = shopinfo.shopInfo;
  let store_client_id = shop_data.store_client_id;
  const header = { authentication: shopinfo?.auth_token , Authorization:shopinfo?.session_token };
  const [settingId, setSettingID] = useState(null);
  const [loading, setLoading] = useState(false);
  let handleBackAction = () => {
    navigate("/back-in-stock");
  };

  const [selectedTab, setSelectedTab] = useState(0);
  const [reminder0, setReminder0] = useState(false);
  const [reminder1, setReminder1] = useState(false);
  const [reminder2, setReminder2] = useState(false);
  const [reminder3, setReminder3] = useState(false);
  const [daysToWait1, setDaysToWait1] = useState(1);
  const [daysToWait2, setDaysToWait2] = useState(2);
  const [daysToWait3, setDaysToWait3] = useState(3);

  let toggleReminder0 = () => {
    setReminder0(!reminder0);
    setReminder1(false);
    setReminder2(false);
    setReminder3(false);
  };
  let toggleReminder1 = () => {
    setReminder1(!reminder1);
  };
  let toggleReminder2 = () => {
    setReminder2(!reminder2);
  };
  let toggleReminder3 = () => {
    setReminder3(!reminder3);
  };
  useEffect(()=>{
    getBackInStockSettings();
  },[])

  const getBackInStockSettings = async () => {
  
    let result = await GetApiCall(
      "GET",
      `/back_in_stock?store_client_id=${store_client_id}`,
      header
    );
    if(result?.data?.statusCode === 200 && result?.data?.status === "success"){
      console.log(result?.data?.data?.activity_update)
      const campaigns = JSON.parse(result?.data?.data?.campaigns);
      if (campaigns) {
      setReminder0(campaigns?.auto_restock_notification || false);
      setReminder1(campaigns?.reminder1?.isEnable || false);
      setDaysToWait1(campaigns?.reminder1?.waiting_days || 1);
      setReminder2(campaigns?.reminder2?.isEnable || false);
      setDaysToWait2(campaigns?.reminder2?.waiting_days || 2);
      setReminder3(campaigns?.reminder3?.isEnable || false);
      setDaysToWait3(campaigns?.reminder3?.waiting_days || 3);
    }
      setSettingID(result?.data?.data?.id);
    }
  };
  const tabs = [
    {
      id: "tab-1",
      content: (
        <Text as="p" variant="bodySm">
          When restock product &nbsp;
          <Badge tone={reminder0 ? "success" : "enabled"}>
            {reminder0 ? "On" : "Off"}
          </Badge>
        </Text>
      ),
      disabled: false,
    },
    {
      id: "tab-2",
      content: (
        <Text as="p" variant="bodySm">
          Reminder 1 &nbsp;
          <Badge tone={reminder1 ? "success" : "enabled"}>
            {reminder1 ? "On" : "Off"}
          </Badge>
        </Text>
      ),
      disabled: !reminder0,
    },
    {
      id: "tab-3",
      content: (
        <Text as="p" variant="bodySm">
          Reminder 2 &nbsp;<Badge>Off</Badge>
        </Text>
      ),
      disabled: !reminder0,
    },
    {
      id: "tab-4",
      content: (
        <Text as="p" variant="bodySm">
          Reminder 3 &nbsp;<Badge>Off</Badge>
        </Text>
      ),
      disabled: !reminder0,
    },
  ];

  let handleSubmit = async() => {
    setLoading(true);

    let data = {
      auto_restock_notification: reminder0,
      reminder1: {
        isEnable: reminder1,
        waiting_days: daysToWait1,
      },
      reminder2: {
        isEnable: reminder2,
        waiting_days: daysToWait2,
      },
      reminder3: {
        isEnable: reminder3,
        waiting_days: daysToWait3,
      },
    };
    let data_back_in_stock = {
      campaigns:data,
      id:settingId
    }
    let res = await ApiCall("PUT", "/update_back_in_stock", data_back_in_stock, header);
    if (res.data.statusCode === 200) {
    console.log(res);
    setLoading(false);
    }
    console.log(data);
  };

  return (
    <Page title="Campaigns" backAction={{ onAction: handleBackAction }}>
      <BlockStack gap={"300"}>
        <Card roundedAbove="xs">
          <BlockStack gap={"400"}>
            <h1 className="h-16">Workflow</h1>
            <Text as="p" variant="bodyLg">
              Boost sales by sending multiple back-in-stock alerts using a
              Notification Schedule. After the initial notification upon
              restock, send up to three reminder notifications to encourage
              product purchases.
            </Text>
            <Card roundedAbove="xs">
              <Bleed marginInline={"400"} marginBlockStart={"400"}>
                <Box borderColor="border" borderBlockEndWidth="025">
                  <Tabs
                    tabs={tabs}
                    selected={selectedTab}
                    onSelect={setSelectedTab}
                  />
                </Box>
              </Bleed>
              {selectedTab == 0 && (
                <Box paddingBlockStart={"400"}>
                  <BlockStack gap={"400"}>
                    <InlineStack
                      gap={"300"}
                      wrap={false}
                      blockAlign="start"
                      align="space-between"
                    >
                      <BlockStack gap={"300"}>
                        <Text as="h1" variant="headingMd">
                          Send notification automatically
                        </Text>
                        <Text as="p" variant="bodyLg">
                          A notification will automatically be sent when you
                          restock the product through the Shopify admin.
                        </Text>
                      </BlockStack>
                      <div style={{ flexShrink: 0 }}>
                        <Button
                          variant={reminder0 ? "secondary" : "primary"}
                          onClick={toggleReminder0}
                        >
                          {reminder0 ? "Disable" : "Enable"}
                        </Button>
                      </div>
                    </InlineStack>
                    <BlockStack gap={"200"} inlineAlign="start">
                      <Text as="h1" variant="headingMd">
                        Notification templates
                      </Text>
                      <Text as="p" variant="bodyLg">
                        Each notification features a unique email template
                        crafted to boost conversions. Customize these templates
                        using the Template Editor to tailor them for this
                        notification.
                      </Text>
                      <Button size="large">Customize email</Button>
                    </BlockStack>
                  </BlockStack>
                </Box>
              )}
              {selectedTab == 1 && (
                <Box paddingBlockStart={"400"}>
                  <BlockStack gap={"400"}>
                    <InlineStack
                      wrap={false}
                      gap={"300"}
                      blockAlign="start"
                      align="space-between"
                    >
                      <BlockStack gap={"300"}>
                        <Text as="h1" variant="headingMd">
                          First reminder notification
                        </Text>
                        <Text as="p" variant="bodyLg">
                          Notifications will be sent {daysToWait1} day
                          {daysToWait1 > 1 && <>&#40;s&#41;</>} after the Back
                          In Stock alert email. This applies only to subscribers
                          who have not yet purchased the product and only if it
                          remains in stock.
                        </Text>
                      </BlockStack>
                      <div style={{ flexShrink: 0 }}>
                        <Button
                          variant={reminder1 ? "secondary" : "primary"}
                          onClick={toggleReminder1}
                        >
                          {reminder1 ? "Disable" : "Enable"}
                        </Button>
                      </div>
                    </InlineStack>
                    <BlockStack gap={"300"}>
                      <BlockStack gap={"200"} inlineAlign="start">
                        <Text as="h1" variant="headingMd">
                          Notification templates
                        </Text>
                        <Text as="p" variant="bodyLg">
                          Personalize the email and SMS templates for the first
                          reminder notification.
                        </Text>
                        <Button size="large">Customize email</Button>
                      </BlockStack>
                      <TextField
                        type="number"
                        value={daysToWait1}
                        onChange={setDaysToWait1}
                        label="Number of days to wait."
                        helpText="Specify the number of days to wait before sending this notification."
                      />
                    </BlockStack>
                  </BlockStack>
                </Box>
              )}
              {selectedTab == 2 && (
                <Box paddingBlockStart={"400"}>
                  <BlockStack gap={"400"}>
                    <InlineStack
                      wrap={false}
                      gap={"300"}
                      blockAlign="start"
                      align="space-between"
                    >
                      <BlockStack gap={"300"}>
                        <Text as="h1" variant="headingMd">
                          First reminder notification
                        </Text>
                        <Text as="p" variant="bodyLg">
                          Notifications will be sent {daysToWait2} day
                          {daysToWait2 > 1 && <>&#40;s&#41;</>} after the Back
                          In Stock alert email. This applies only to subscribers
                          who have not yet purchased the product and only if it
                          remains in stock.
                        </Text>
                      </BlockStack>
                      <div style={{ flexShrink: 0 }}>
                        <Button
                          variant={reminder2 ? "secondary" : "primary"}
                          onClick={toggleReminder2}
                        >
                          {reminder2 ? "Disable" : "Enable"}
                        </Button>
                      </div>
                    </InlineStack>
                    <BlockStack gap={"300"}>
                      <BlockStack gap={"200"} inlineAlign="start">
                        <Text as="h1" variant="headingMd">
                          Notification templates
                        </Text>
                        <Text as="p" variant="bodyLg">
                          Personalize the email and SMS templates for the first
                          reminder notification.
                        </Text>
                        <Button size="large">Customize email</Button>
                      </BlockStack>
                      <TextField
                        type="number"
                        value={daysToWait2}
                        onChange={setDaysToWait2}
                        label="Number of days to wait."
                        helpText="Specify the number of days to wait before sending this notification."
                      />
                    </BlockStack>
                  </BlockStack>
                </Box>
              )}
              {selectedTab == 3 && (
                <Box paddingBlockStart={"400"}>
                  <BlockStack gap={"400"}>
                    <InlineStack
                      wrap={false}
                      gap={"300"}
                      blockAlign="start"
                      align="space-between"
                    >
                      <BlockStack gap={"300"}>
                        <Text as="h1" variant="headingMd">
                          First reminder notification
                        </Text>
                        <Text as="p" variant="bodyLg">
                          Notifications will be sent {daysToWait3} day
                          {daysToWait3 > 1 && <>&#40;s&#41;</>} after the Back
                          In Stock alert email. This applies only to subscribers
                          who have not yet purchased the product and only if it
                          remains in stock.
                        </Text>
                      </BlockStack>
                      <div style={{ flexShrink: 0 }}>
                        <Button
                          variant={reminder3 ? "secondary" : "primary"}
                          onClick={toggleReminder3}
                        >
                          {reminder3 ? "Disable" : "Enable"}
                        </Button>
                      </div>
                    </InlineStack>
                    <BlockStack gap={"300"}>
                      <BlockStack gap={"200"} inlineAlign="start">
                        <Text as="h1" variant="headingMd">
                          Notification templates
                        </Text>
                        <Text as="p" variant="bodyLg">
                          Personalize the email and SMS templates for the first
                          reminder notification.
                        </Text>
                        <Button size="large">Customize email</Button>
                      </BlockStack>
                      <TextField
                        type="number"
                        value={daysToWait3}
                        onChange={setDaysToWait3}
                        label="Number of days to wait."
                        helpText="Specify the number of days to wait before sending this notification."
                      />
                    </BlockStack>
                  </BlockStack>
                </Box>
              )}
            </Card>
          </BlockStack>
        </Card>
        <InlineStack align="end">
          <Button loading={loading} variant="primary" onClick={handleSubmit}>
            Save
          </Button>
        </InlineStack>
      </BlockStack>
    </Page>
  );
};

export default BackInStock_setting_campaigns;
