import React from "react";
import { Button } from "@shopify/polaris";
import { useNavigate } from "react-router-dom";

const CustomHeader = ({ Header, ButtonName, Pricingbtn }) => {
  const navigate = useNavigate();

  return (
    <div className="header d-flex justify-content-between py-3 px-5 border-bottom border-secondary">
      <h1 className="Polaris-Header-Title pt-1">{Header}</h1>
      <div className="d-flex">
        <div className="me-3">
          <Button>{ButtonName}</Button>
        </div>
        <div>
          <Button onClick={() => navigate("/pricingplan")}>{Pricingbtn}</Button>
        </div>
      </div>
    </div>
  );
};

export default CustomHeader;
